import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ThemeContext from 'components/context/ThemeContext';
import logo from 'assets/images/svg/VirtuSwap_text_originalWhite.svg';
import logo_Light from 'assets/images/svg/VirtuSwap_text_originalBlack.svg';

const AppLoader = ({ message }) => {
    const { theme } = useContext(ThemeContext);

    return (
        <div
            className={`comingSoonBanner ${!!message ? 'p-0' : ''}`}
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="logo_bar appLoad text-center">
                            <Link to="/" className="navbar-brand">
                                <img
                                    src={theme === 'dark' ? logo : logo_Light}
                                    alt=""
                                    className="img-fluid appLoader"
                                />
                            </Link>
                        </div>
                        {!!message && (
                            <div className="deviceAccess">
                                <p className="text-center">{message}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AppLoader;
