import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import AppLoader from 'components/common/AppLoader';
import ThemeContextProvider from 'components/context/ThemeContextProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'assets/css/customdroddown.css';
import 'assets/css/style.css';
import 'assets/css/responsive.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = lazy(() => import('./App'));

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <Suspense fallback={<AppLoader />}>
                <ThemeContextProvider>
                    <App />
                </ThemeContextProvider>
            </Suspense>
        </Provider>
    </BrowserRouter>
);
reportWebVitals();
