import {
    FETCH_VRSW_POSITIONS,
    FETCH_VRSW_POSITIONS_SUCCESS,
    FETCH_VRSW_POSITIONS_ERROR,
    SET_VRSW_POOL_BLOCK,
    SET_VRSW_POOL_LOADER,
    FETCH_BOOST_CAL,
    FETCH_BOOST_CAL_SUCCESS,
    FETCH_BOOST_CAL_ERROR,
    SET_STAKING_LP_TOKENS,
    SET_STAKING_LP_TOKENS_SUCCESS,
    SET_STAKING_LP_TOKENS_ERROR,
} from './actionType';

const initialStates = {
    VRSW_Positions: {},
    VRSWPoolBlock: [],
    vrswLoader: true,
    BoostCalculator: {
        boost: 1,
        resultingApr: 0,
        vrswApr: 0,
    },
    StakingLpTokens: {},
    BoostCalculatorLoader: false,
};

export const VRSWReducer = (state = initialStates, action) => {
    const { type, payload } = action;
    switch (type) {
        //Fetch VRSW Positions
        case FETCH_VRSW_POSITIONS:
            return {
                ...state,
            };
        case FETCH_VRSW_POSITIONS_SUCCESS:
            return {
                ...state,
                VRSW_Positions: payload,
            };
        case FETCH_VRSW_POSITIONS_ERROR:
            return {
                ...state,
                VRSW_Positions: {},
            };
        //Set VRSW Pool Block
        case SET_VRSW_POOL_BLOCK:
            return {
                ...state,
                VRSWPoolBlock: payload,
                vrswLoader: false,
            };
        //Set VRSW Pool Loader
        case SET_VRSW_POOL_LOADER:
            return {
                ...state,
                vrswLoader: payload,
            };
        //Fetch Boost Calculator
        case FETCH_BOOST_CAL:
            return {
                ...state,
                BoostCalculatorLoader: true,
            };
        case FETCH_BOOST_CAL_SUCCESS:
            return {
                ...state,
                BoostCalculator: payload,
                BoostCalculatorLoader: false,
            };
        case FETCH_BOOST_CAL_ERROR:
            return {
                ...state,
                BoostCalculator: {
                    boost: 1,
                    resultingApr: 0,
                    vrswApr: 0,
                },
                BoostCalculatorLoader: false,
            };
        //Set Staking LP Tokens list
        case SET_STAKING_LP_TOKENS:
            return {
                ...state,
            };
        case SET_STAKING_LP_TOKENS_SUCCESS:
            return {
                ...state,
                StakingLpTokens: payload,
            };
        case SET_STAKING_LP_TOKENS_ERROR:
            return {
                ...state,
                StakingLpTokens: {},
            };
        default:
            return state;
    }
};

export default VRSWReducer;
