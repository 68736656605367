import {
    FETCH_VRSW_POSITIONS,
    FETCH_VRSW_POSITIONS_SUCCESS,
    FETCH_VRSW_POSITIONS_ERROR,
    SET_VRSW_POOL_BLOCK,
    SET_VRSW_POOL_LOADER,
    FETCH_BOOST_CAL,
    FETCH_BOOST_CAL_SUCCESS,
    FETCH_BOOST_CAL_ERROR,
    SET_STAKING_LP_TOKENS,
    SET_STAKING_LP_TOKENS_SUCCESS,
    SET_STAKING_LP_TOKENS_ERROR,
} from './actionType';
import { FETCH_VRSW_PS, GET_BOOST_CAL } from 'helpers/url_helper';
import { axiosApiVirtuSwap } from 'helpers/api_helper';
import vSwapHelpers from 'util/vSwapHelpers';
import _ from 'lodash';
import moment from 'moment';
import { Decimal } from 'decimal.js-light';
import { defaultChainId } from 'components/DummyData';
import { handleThousandSeparator } from 'components/common/CommonFunctions';

//Fetch VRSW Positions
export const handleFetchVRSWPositions = (chainId, address, cb = () => {}, payload) => {
    return async (dispatch) => {
        try {
            chainId ??= defaultChainId;
            dispatch(setVRSWPoolLoader(true));
            dispatch(fetchVRSWPositions(true));
            const res = await axiosApiVirtuSwap.get(
                FETCH_VRSW_PS + '?wallet=' + address + '&chainId=' + chainId,
                payload
            );
            if (res.data) {
                dispatch(fetchVRSWPositionsSuccess(res.data));
                cb(res.data);
            }
        } catch (err) {
            dispatch(fetchVRSWPositionsError(err.message));
        }
    };
};

//Fetch Boost Calculator
export const getHandleBoostCal = (
    chainId,
    isWalletAddress,
    Amount,
    RangeValue,
    payload
) => {
    return async (dispatch) => {
        try {
            Amount = Amount.toFixed?.(18) ?? Amount;
            chainId ??= defaultChainId;
            dispatch(fetchBoostCal(true));
            const res = await axiosApiVirtuSwap.get(
                `${GET_BOOST_CAL}?wallet=${isWalletAddress}&amount=${Amount}&duration=${RangeValue}&chainId=${chainId}`,
                payload
            );
            dispatch(fetchBoostCalSuccess(res.data || 0));
        } catch (err) {
            dispatch(fetchBoostCalError(err.message));
        }
    };
};

//Set Staking LP Tokens list
export const handleSetStakingLPTokens = (
    VRSW_Positions,
    ProfileToken,
    PoolsList,
    isScanPools,
    setIUnStakeLoader,
    isViewLpStakeAmount,
    IsClaimRewards,
    setIsStakeLoader
) => {
    return async (dispatch) => {
        try {
            dispatch(SetStakingLPTokens(true));
            if (!_.isEmpty(VRSW_Positions)) {
                dispatch(SetStakingLPTokensSuccess(VRSW_Positions));
                // Staked LP Positions
                let stakedPosition = [];
                let tokensPool = [];
                if (!!isViewLpStakeAmount?.length) {
                    isViewLpStakeAmount?.map((item) => {
                        let pool = item?.lpToken?.toLowerCase();
                        let Vlp = PoolsList?.find(
                            (pt) => pool === pt?.poolAddress
                        );
                        if (Vlp) {
                            tokensPool.push({
                                item,
                                ...Vlp,
                            });
                        }
                        return Vlp;
                    });
                }
                let tokens = tokensPool?.map((pl, index) => {
                    let tokenFirst = ProfileToken?.find(
                        (pt) => pl?.token0 === pt?.address
                    );
                    let tokenSecond = ProfileToken?.find(
                        (pt) => pl?.token1 === pt?.address
                    );
                    return {
                        pool: pl,
                        tokenFirst,
                        tokenSecond,
                        claimRewards: IsClaimRewards?.viewLp[index],
                        amount: Number(pl?.item?.amount),
                        bn: Number(pl?.item?.bn),
                        APR: pl?.totalAPY ? Number(pl?.totalAPY) : 0,
                    };
                });
                if (!!tokens?.length) {
                    stakedPosition = await Promise.all(
                        tokens?.map(async (item, index) => {
                            //*** PoolTVL = ((pool balance0 * first token lastUSDPrice)+(pool balance1 * Second token lastUSDPrice)) * (1 + pool reserveRatio) */
                            let PoolTVL =
                                Number(item?.pool?.balance0) *
                                    Number(item?.tokenFirst?.lastUSDPrice) +
                                Number(item?.pool?.balance1) *
                                    Number(item?.tokenSecond?.lastUSDPrice);
                            //*** my_balance = PoolTVL *  (bn / lpTokens ) */
                            let totalLpTokens = parseFloat(
                                item?.pool?.lpTokens
                            );
                            let myLp = Number(item?.amount);
                            let my_balance = PoolTVL * (myLp / totalLpTokens);
                            let VRSWToken =
                                !!ProfileToken.length &&
                                ProfileToken?.find(
                                    (item) => item?.symbol === 'VRSW'
                                );
                            PoolTVL *= (1 + Number(item?.pool?.reserveRatio) / 100000);
                            // Set Staked APR
                            let Staked_APR = {};
                            if (!!VRSW_Positions?.stakedPositions?.length) {
                                Staked_APR =
                                    VRSW_Positions?.stakedPositions?.find(
                                        (i) =>
                                            i?.pool?.toLowerCase() ===
                                            item?.pool?.poolAddress?.toLowerCase()
                                    );
                            }
                            //view LP Rewards
                            let view_lp_rewards = 0;
                            let view_lp_PartnerRewards = 0;
                            let claimUSDValue = 0;
                            if (Number(item?.pool?.partnerTotalTokens) > 0) {
                                //view LP Partner Rewards
                                view_lp_PartnerRewards =
                                    await vSwapHelpers.viewLPPartnerRewards(
                                        item?.pool?.poolAddress,
                                        item?.pool?.partnerToken
                                    );
                                //Claim USD Value
                                claimUSDValue =
                                    Number(view_lp_PartnerRewards) *
                                    Number(item?.pool?.partnerLastUSDPrice);
                            }
                            //view LP Rewards
                            view_lp_rewards = await vSwapHelpers.viewLPRewards(
                                item?.pool?.poolAddress
                            );
                            //Claim VRSW USD Value
                            let VRSW_USDValue =
                                Number(view_lp_rewards) *
                                Number(VRSWToken?.lastUSDPrice);
                            //Claim Reward Amount
                            let cellAmount =
                                Number(VRSW_USDValue) + Number(claimUSDValue);
                            //Claimable Reward List
                            let ClaimableRewardList = [
                                {
                                    id: 1,
                                    Asset: VRSWToken?.symbol,
                                    Units: handleThousandSeparator(Number.parseFloat(
                                        Number(item?.claimRewards)?.toFixed(2)
                                    )),
                                    icon: VRSWToken?.icon,
                                    cell: VRSW_USDValue
                                        ? `$${handleThousandSeparator(Number.parseFloat(
                                              Number(VRSW_USDValue)?.toFixed(2)
                                          ))}`
                                        : '$0',
                                },
                            ];
                            if (Number(view_lp_PartnerRewards) > 0) {
                                ClaimableRewardList.push({
                                    id: ClaimableRewardList?.length + 1,
                                    Asset: item?.pool?.partnerName,
                                    Units: handleThousandSeparator(Number.parseFloat(
                                        Number(view_lp_PartnerRewards)?.toFixed(
                                            2
                                        )
                                    )),
                                    icon: item?.pool?.partnerLogo,
                                    cell: claimUSDValue
                                        ? `$${handleThousandSeparator(Number.parseFloat(
                                              Number(claimUSDValue)?.toFixed(2)
                                          ))}`
                                        : '$0',
                                });
                            }
                            let StakedAPRPoolModalList = [
                                {
                                    id: 1,
                                    Asset: 'Trading fees APR:',
                                    cell: item?.pool?.feeAPY
                                        ? `${handleThousandSeparator(Number.parseFloat(
                                              Number(
                                                  item?.pool?.feeAPY
                                              )?.toFixed(2)
                                          ))}%`
                                        : '0%',
                                },
                                {
                                    id: 2,
                                    Asset: 'VRSW rewards APR:',
                                    cell: Staked_APR?.stakedVrswApr
                                        ? `${handleThousandSeparator(Number.parseFloat(
                                              Number(
                                                  Staked_APR?.stakedVrswApr
                                              )?.toFixed(2)
                                          ))}%`
                                        : '0%',
                                },
                            ];
                            if (Number(Staked_APR?.stakedPartnerApr) > 0) {
                                StakedAPRPoolModalList.push({
                                    id: 3,
                                    Asset: 'Partner rewards APR:',
                                    cell: Staked_APR?.stakedPartnerApr
                                        ? `${handleThousandSeparator(Number.parseFloat(
                                              Number(
                                                  Staked_APR?.stakedPartnerApr
                                              )?.toFixed(2)
                                          ))}%`
                                        : '0%',
                                });
                            }
                            let APRPoolModalList = [
                                {
                                    id: 1,
                                    Asset: 'Trading fees APR:',
                                    cell: item?.pool?.feeAPY
                                        ? `${handleThousandSeparator(Number.parseFloat(
                                              Number(
                                                  item?.pool?.feeAPY
                                              )?.toFixed(2)
                                          ))}%`
                                        : '0%',
                                },
                                {
                                    id: 2,
                                    Asset: 'VRSW rewards APR:',
                                    cell: item?.pool?.vrswAPY
                                        ? `${handleThousandSeparator(Number.parseFloat(
                                              Number(
                                                  item?.pool?.vrswAPY
                                              )?.toFixed(2)
                                          ))}%`
                                        : '0%',
                                },
                            ];
                            if (Number(item?.pool?.partnerAPY) > 0) {
                                APRPoolModalList.push({
                                    id: 3,
                                    Asset: 'Partner rewards APR:',
                                    cell: item?.pool?.partnerAPY
                                        ? `${handleThousandSeparator(Number.parseFloat(
                                              Number(
                                                  item?.pool?.partnerAPY
                                              )?.toFixed(2)
                                          ))}%`
                                        : '0%',
                                });
                            }
                            return {
                                view_lp_rewards,
                                id: index + 1,
                                title: `${item?.tokenFirst?.symbol}-${item?.tokenSecond?.symbol}`,
                                token1Icon: item?.tokenFirst?.tokenId,
                                token2Icon: item?.tokenSecond?.tokenId,
                                pool: item?.pool,
                                bn: item?.bn,
                                APR: item?.APR,
                                amount: item?.amount,
                                claimAmount: item?.claimRewards,
                                cellAmount: cellAmount,
                                ClaimableRewardList: ClaimableRewardList,
                                rewardModalList: [
                                    {
                                        id: 1,
                                        Asset: item?.pool?.partnerName,
                                        // Units: Number.parseFloat(
                                        //     Number(
                                        //         item?.pool?.partnerTotalTokens
                                        //     )?.toFixed(2)
                                        // ),
                                        icon: item?.pool?.partnerLogo,
                                        cell: item?.pool?.partnerAPY
                                            ? `${handleThousandSeparator(Number.parseFloat(
                                                  Number(
                                                      item?.pool?.partnerAPY
                                                  )?.toFixed(2)
                                              ))}%`
                                            : '0%',
                                    },
                                    {
                                        id: 2,
                                        Asset: VRSWToken?.symbol,
                                        Units: handleThousandSeparator(Number.parseFloat(
                                            item?.pool?.totalVRSW
                                        )),
                                        icon: VRSWToken?.icon,
                                        cell: item?.pool?.vrswAPY
                                            ? `${handleThousandSeparator(Number.parseFloat(
                                                  Number(
                                                      item?.pool?.vrswAPY
                                                  )?.toFixed(2)
                                              ))}%`
                                            : '0%',
                                    },
                                ],
                                block_desc: [
                                    {
                                        id: 1,
                                        block_title: 'Pair',
                                    },
                                    {
                                        id: 2,
                                        block_title: 'My Balance',
                                        // block_data: item?.myBalance,
                                        block_data: my_balance,
                                        tooltip: '',
                                        class_name: 'setCol2',
                                    },
                                    {
                                        id: 3,
                                        block_title: 'Pool Value',
                                        block_data: PoolTVL,
                                        tooltip: '',
                                        class_name: 'setCol3',
                                    },
                                    {
                                        id: 4,
                                        block_title: 'Average pool APR',
                                        block_data: Number(item?.APR)
                                            ? handleThousandSeparator(Number.parseFloat(
                                                  Number(item?.APR)?.toFixed(2)
                                              ))
                                            : 0,
                                        tooltip: '',
                                        block_data_tooltip: '',
                                        boosted: true,
                                        class_name: 'setCol4',
                                        APRModalList: true,
                                        APRPoolModalList: APRPoolModalList,
                                    },

                                    // {
                                    //     id: 7,
                                    //     block_title: '',
                                    //     row_boosted: true,
                                    // },
                                    {
                                        id: 8,
                                        block_title: 'My APR',
                                        // block_data: item?.apr,
                                        block_data: Staked_APR?.totalStakedApr
                                            ? handleThousandSeparator(Number.parseFloat(
                                                  Number(
                                                      Staked_APR?.totalStakedApr
                                                  )?.toFixed(2)
                                              ))
                                            : 0,
                                        tooltip: '',
                                        boosted: true,
                                        class_name: 'setCol5',
                                        APRModalList: true,
                                        APRPoolModalList: StakedAPRPoolModalList,
                                    },
                                    {
                                        id: 9,
                                        block_title: 'Possible APR',
                                        block_data: Staked_APR?.totalPossibleApr
                                            ? handleThousandSeparator(Number.parseFloat(
                                                  Number(
                                                      Staked_APR?.totalPossibleApr
                                                  )?.toFixed(2)
                                              ))
                                            : 0,
                                        tooltip: '',
                                        class_name: 'setCol7',
                                        APRModalList: true,
                                        APRPoolModalList: APRPoolModalList.map(
                                            (item) => {
                                                if (item.id === 2) {
                                                    return {
                                                        ...item,
                                                        cell: Staked_APR?.possibleVrswApr
                                                            ? `${handleThousandSeparator(Number.parseFloat(
                                                                  Number(
                                                                      Staked_APR?.possibleVrswApr
                                                                  )?.toFixed(2)
                                                              ))}%`
                                                            : '0%',
                                                    };
                                                }
                                                if (item.id === 3) {
                                                    return {
                                                        ...item,
                                                        cell: Staked_APR?.possiblePartnerApr
                                                            ? `${handleThousandSeparator(Number.parseFloat(
                                                                  Number(
                                                                      Staked_APR?.possiblePartnerAprr
                                                                  )?.toFixed(2)
                                                              ))}%`
                                                            : '0%',
                                                    };
                                                } else {
                                                    return item;
                                                }
                                            }
                                        ),
                                    },
                                    {
                                        id: 5,
                                        block_title: 'Claimable Rewards',
                                        block_data: cellAmount ? cellAmount : 0,
                                        tooltip: '',
                                        class_name: 'setCol8',
                                    },
                                    {
                                        id: 6,
                                        block_title: 'Action',
                                    },
                                ],
                            };
                        })
                    );
                }

                // UnStaked LP positions - stake now to start making APR
                let tokensPoolUnStaked = isScanPools?.map((item) => {
                    let pool = item?.poolAddress?.toLowerCase();
                    return PoolsList?.find((pt) => pool === pt?.poolAddress);
                });
                let tokensUnStaked = tokensPoolUnStaked?.map((pl, index) => {
                    let tokenFirst = ProfileToken?.find(
                        (pt) => pl?.token0 === pt?.address
                    );
                    let tokenSecond = ProfileToken?.find(
                        (pt) => pl?.token1 === pt?.address
                    );
                    return {
                        pool: pl,
                        tokenFirst,
                        tokenSecond,
                        claimRewards: IsClaimRewards?.scanLp[index],
                        APR: pl?.totalAPY ? Number(pl?.totalAPY) : 0,
                    };
                });
                const UnStakedPosition = await Promise.all(
                    tokensUnStaked?.map(async (item, index) => {
                        //*** PoolTVL = ((pool balance0 * first token lastUSDPrice)+(pool balance1 * Second token lastUSDPrice)) * (1 + pool reserveRatio) */
                        let PoolTVL =
                            Number(item?.pool?.balance0) *
                                Number(item?.tokenFirst?.lastUSDPrice) +
                            Number(item?.pool?.balance1) *
                                Number(item?.tokenSecond?.lastUSDPrice);

                        let totalLpTokens = parseFloat(item?.pool?.lpTokens);
                        let myLp = isScanPools[index].balance;
                        let my_balance = PoolTVL * (myLp / totalLpTokens);
                        let VRSWToken =
                            !!ProfileToken.length &&
                            ProfileToken?.find(
                                (item) => item?.symbol === 'VRSW'
                            );
                        PoolTVL *= (1 + Number(item?.pool?.reserveRatio) / 100000);
                        // Set UnStaked APR
                        let UnStaked_APR = {};
                        if (!!VRSW_Positions?.summary?.distribution?.length) {
                            UnStaked_APR =
                                VRSW_Positions?.summary?.distribution?.find(
                                    (i) =>
                                        i?.pool?.toLowerCase() ===
                                        item?.pool?.poolAddress?.toLowerCase()
                                );
                        }
                        //view LP Rewards
                        let view_lp_rewards = 0;
                        let view_lp_PartnerRewards = 0;
                        let claimUSDValue = 0;
                        if (Number(item?.pool?.partnerTotalTokens) > 0) {
                            //view LP Partner Rewards
                            view_lp_PartnerRewards =
                                await vSwapHelpers.viewLPPartnerRewards(
                                    item?.pool?.poolAddress,
                                    item?.pool?.partnerToken
                                );
                            //Claim USD Value
                            claimUSDValue =
                                Number(view_lp_PartnerRewards) *
                                Number(item?.pool?.partnerLastUSDPrice);
                        }
                        //view LP Rewards
                        view_lp_rewards = await vSwapHelpers.viewLPRewards(
                            item?.pool?.poolAddress
                        );
                        //Claim VRSW USD Value
                        let VRSW_USDValue =
                            Number(view_lp_rewards) *
                            Number(VRSWToken?.lastUSDPrice);
                        //Claim Reward Amount
                        let cellAmount =
                            Number(VRSW_USDValue) + Number(claimUSDValue);
                        //Claimable Reward List
                        let ClaimableRewardList = [
                            {
                                id: 1,
                                Asset: VRSWToken?.symbol,
                                Units: handleThousandSeparator(Number.parseFloat(
                                    Number(item?.claimRewards)?.toFixed(2)
                                )),
                                icon: VRSWToken?.icon,
                                cell: VRSW_USDValue
                                    ? `$${handleThousandSeparator(Number.parseFloat(
                                          Number(VRSW_USDValue)?.toFixed(2)
                                      ))}`
                                    : '$0',
                            },
                        ];
                        if (Number(item?.pool?.partnerTotalTokens) > 0) {
                            ClaimableRewardList.push({
                                id: ClaimableRewardList?.length + 1,
                                Asset: item?.pool?.partnerName,
                                Units: handleThousandSeparator(Number.parseFloat(
                                    Number(view_lp_PartnerRewards)?.toFixed(2)
                                )),
                                icon: item?.pool?.partnerLogo,
                                cell: claimUSDValue
                                    ? `$${handleThousandSeparator(Number.parseFloat(
                                          Number(claimUSDValue)?.toFixed(2)
                                      ))}`
                                    : '$0',
                            });
                        }
                        let StakedAPRPoolModalList = [
                            {
                                id: 1,
                                Asset: 'Trading fees APR:',
                                cell: item?.pool?.feeAPY
                                    ? `${handleThousandSeparator(Number.parseFloat(
                                          Number(item?.pool?.feeAPY)?.toFixed(2)
                                      ))}%`
                                    : '0%',
                            },
                            {
                                id: 2,
                                Asset: 'VRSW rewards APR:',
                                cell: UnStaked_APR?.unstakedVrswApr
                                    ? `${handleThousandSeparator(Number.parseFloat(
                                          Number(
                                              UnStaked_APR?.unstakedVrswApr
                                          )?.toFixed(2)
                                      ))}%`
                                    : '0%',
                            },
                        ];
                        if (Number(UnStaked_APR?.unstakedPartnerApr) > 0) {
                            StakedAPRPoolModalList.push({
                                id: 3,
                                Asset: 'Partner rewards APR:',
                                cell: UnStaked_APR?.unstakedPartnerApr
                                    ? `${handleThousandSeparator(Number.parseFloat(
                                          Number(
                                              UnStaked_APR?.unstakedPartnerApr
                                          )?.toFixed(2)
                                      ))}%`
                                    : '0%',
                            });
                        }
                        let APRPoolModalList = [
                            {
                                id: 1,
                                Asset: 'Trading fees APR:',
                                cell: item?.pool?.feeAPY
                                    ? `${handleThousandSeparator(Number.parseFloat(
                                          Number(item?.pool?.feeAPY)?.toFixed(2)
                                      ))}%`
                                    : '0%',
                            },
                            {
                                id: 2,
                                Asset: 'VRSW rewards APR:',
                                cell: item?.pool?.vrswAPY
                                    ? `${handleThousandSeparator(Number.parseFloat(
                                          Number(
                                              item?.pool?.vrswAPY
                                          )?.toFixed(2)
                                      ))}%`
                                    : '0%',
                            },
                        ];
                        if (Number(item?.pool?.partnerAPY) > 0) {
                            APRPoolModalList.push({
                                id: 3,
                                Asset: 'Partner rewards APR:',
                                cell: item?.pool?.partnerAPY
                                    ? `${handleThousandSeparator(Number.parseFloat(
                                          Number(
                                              item?.pool?.partnerAPY
                                          )?.toFixed(2)
                                      ))}%`
                                    : '0%',
                            });
                        }
                        return {
                            view_lp_rewards,
                            id: index + 1,
                            title: `${item?.tokenFirst?.symbol}-${item?.tokenSecond?.symbol}`,
                            token1Icon: item?.tokenFirst?.tokenId,
                            token2Icon: item?.tokenSecond?.tokenId,
                            APR: item?.APR,
                            pool: item?.pool,
                            isScanPools: isScanPools[index],
                            claimAmount: item?.claimRewards,
                            cellAmount: cellAmount,
                            ClaimableRewardList: ClaimableRewardList,
                            rewardModalList: [
                                {
                                    id: 1,
                                    Asset: item?.pool?.partnerName,
                                    // Units: Number.parseFloat(
                                    //     Number(
                                    //         item?.pool?.partnerTotalTokens
                                    //     )?.toFixed(2)
                                    // ),
                                    icon: item?.pool?.partnerLogo,
                                    cell: item?.pool?.partnerAPY
                                        ? `${handleThousandSeparator(Number.parseFloat(
                                              Number(
                                                  item?.pool?.partnerAPY
                                              )?.toFixed(2)
                                          ))}%`
                                        : '0%',
                                },
                                {
                                    id: 2,
                                    Asset: VRSWToken?.symbol,
                                    Units: handleThousandSeparator(Number.parseFloat(
                                        item?.pool?.totalVRSW
                                    )),
                                    icon: VRSWToken?.icon,
                                    cell: item?.pool?.vrswAPY
                                        ? `${handleThousandSeparator(Number.parseFloat(
                                              Number(
                                                  item?.pool?.vrswAPY
                                              )?.toFixed(2)
                                          ))}%`
                                        : '0%',
                                },
                            ],
                            block_desc: [
                                {
                                    id: 1,
                                    block_title: 'Pair',
                                },
                                {
                                    id: 2,
                                    block_title: 'My Balance',
                                    block_data: my_balance,
                                    tooltip: '',
                                    block_data_tooltip: '',
                                    class_name: 'setCol2',
                                },
                                {
                                    id: 3,
                                    block_title: 'Pool Value',
                                    block_data: PoolTVL,
                                    tooltip: '',
                                    block_data_tooltip: '',
                                    class_name: 'setCol3',
                                },
                                {
                                    id: 4,
                                    block_title: 'Average pool APR',
                                    block_data: Number(item?.APR)
                                        ? handleThousandSeparator(Number.parseFloat(
                                              Number(item?.APR)?.toFixed(2)
                                          ))
                                        : 0,
                                    tooltip: '',
                                    block_data_tooltip: '',
                                    boosted: true,
                                    class_name: 'setCol4',
                                    APRModalList: true,
                                    APRPoolModalList: APRPoolModalList,
                                },
                                // {
                                //     id: 7,
                                //     block_title: '',
                                //     row_boosted: true,
                                // },
                                {
                                    id: 8,
                                    block_title: 'My APR',
                                    block_data: UnStaked_APR?.totalUnstakedApr
                                        ? handleThousandSeparator(Number.parseFloat(
                                              Number(
                                                  UnStaked_APR?.totalUnstakedApr
                                              )?.toFixed(2)
                                          ))
                                        : 0,
                                    tooltip: '',
                                    class_name: 'setCol5',
                                    APRModalList: true,
                                    APRPoolModalList: StakedAPRPoolModalList,
                                },
                                {
                                    id: 9,
                                    block_title: 'Possible APR',
                                    block_data: UnStaked_APR?.totalPossibleApr
                                        ? handleThousandSeparator(Number.parseFloat(
                                              Number(
                                                  UnStaked_APR?.totalPossibleApr
                                              )?.toFixed(2)
                                          ))
                                        : 0,
                                    tooltip: '',
                                    class_name: 'setCol7',
                                    APRModalList: true,
                                    APRPoolModalList: APRPoolModalList.map(
                                        (item) => {
                                            if (item.id === 2) {
                                                return {
                                                    ...item,
                                                    cell: UnStaked_APR?.possibleVrswApr
                                                        ? `${handleThousandSeparator(Number.parseFloat(
                                                              Number(
                                                                  UnStaked_APR?.possibleVrswApr
                                                              )?.toFixed(2)
                                                          ))}%`
                                                        : '0%',
                                                };
                                            }
                                            if (item.id === 3) {
                                                return {
                                                    ...item,
                                                    cell: UnStaked_APR?.possiblePartnerApr
                                                        ? `${handleThousandSeparator(Number.parseFloat(
                                                              Number(
                                                                  UnStaked_APR?.possiblePartnerApr
                                                              )?.toFixed(2)
                                                          ))}%`
                                                        : '0%',
                                                };
                                            } else {
                                                return item;
                                            }
                                        }
                                    ),
                                },
                                {
                                    id: 5,
                                    block_title: 'Claimable Rewards',
                                    block_data: cellAmount ? cellAmount : 0,
                                    tooltip: '',
                                    class_name: 'setCol8',
                                },
                                {
                                    id: 6,
                                    block_title: 'Action',
                                },
                            ],
                        };
                    })
                );
                //VRSW Claimable Amount
                let VRSWCellAmount = 0;
                if (VRSW_Positions) {
                    let VRSWToken =
                        !!ProfileToken.length &&
                        ProfileToken?.find((item) => item?.symbol === 'VRSW');
                    let cellAmount = Number(VRSW_Positions?.vrsw?.claimable);
                    VRSWCellAmount =
                        Number(VRSWToken?.lastUSDPrice) * Number(cellAmount);
                }
                //Total Claimable Rewards
                let TotalClaimableRewards = 0;
                if (!!stakedPosition?.length || !!UnStakedPosition?.length) {
                    let stakeReward =
                        !!stakedPosition?.length &&
                        stakedPosition?.reduce(
                            (acc, curr) => acc + Number(curr?.cellAmount),
                            0
                        );
                    let unStakeReward = UnStakedPosition?.reduce(
                        (acc, curr) => acc + Number(curr?.cellAmount),
                        0
                    );
                    TotalClaimableRewards =
                        Number(stakeReward) +
                        Number(unStakeReward) +
                        Number(VRSWCellAmount);
                }
                //Set Start & End Epoch
                let Start_Epoch = VRSW_Positions?.summary?.epoch?.start;
                let End_Epoch = VRSW_Positions?.summary?.epoch?.end;
                Start_Epoch = moment(Start_Epoch).format('DD/MM/YYYY');
                End_Epoch = moment(End_Epoch).format('DD/MM/YYYY');

                //Final UnStaked Lp Position List
                let UnStaked_Lp_Position_List = UnStakedPosition?.filter(
                    (unstakedItem) =>
                        (!new Decimal(unstakedItem?.view_lp_rewards).isZero() &&
                            !stakedPosition.some(
                                (stakedItem) =>
                                    unstakedItem?.pool?.poolAddress?.toLowerCase() ===
                                    stakedItem?.pool?.poolAddress?.toLowerCase()
                            )) ||
                        !new Decimal(
                            unstakedItem?.isScanPools?.balance
                        ).isZero()
                );
                dispatch(
                    setVRSWPoolBlock({
                        stakedLpPosition: stakedPosition,
                        VRSW_summary: VRSW_Positions?.summary,
                        UnStakedLpPosition: UnStaked_Lp_Position_List,
                        TotalClaimableRewards: TotalClaimableRewards,
                        StartEpoch: Start_Epoch,
                        EndEpoch: End_Epoch,
                    })
                );
                if (!!UnStakedPosition.length) {
                    setIUnStakeLoader(false);
                }
                if (!!stakedPosition.length) {
                    setIsStakeLoader(false);
                }
            }
        } catch (err) {
            dispatch(SetStakingLPTokensError(err.message));
        }
    };
};

//Fetch VRSW Positions
export const fetchVRSWPositions = (data) => ({
    type: FETCH_VRSW_POSITIONS,
    payload: data,
});
export const fetchVRSWPositionsSuccess = (data) => ({
    type: FETCH_VRSW_POSITIONS_SUCCESS,
    payload: data,
});
export const fetchVRSWPositionsError = (data) => ({
    type: FETCH_VRSW_POSITIONS_ERROR,
    payload: data,
});
//Set VRSW Pool Block
export const setVRSWPoolBlock = (data) => ({
    type: SET_VRSW_POOL_BLOCK,
    payload: data,
});
//Set VRSW Pool Loader
export const setVRSWPoolLoader = (data) => ({
    type: SET_VRSW_POOL_LOADER,
    payload: data,
});
//Fetch Boost Calculator
export const fetchBoostCal = (data) => ({
    type: FETCH_BOOST_CAL,
    payload: data,
});
export const fetchBoostCalSuccess = (data) => ({
    type: FETCH_BOOST_CAL_SUCCESS,
    payload: data,
});
export const fetchBoostCalError = (data) => ({
    type: FETCH_BOOST_CAL_ERROR,
    payload: data,
});
//Set Staking LP Tokens list
export const SetStakingLPTokens = (data) => ({
    type: SET_STAKING_LP_TOKENS,
    payload: data,
});
export const SetStakingLPTokensSuccess = (data) => ({
    type: SET_STAKING_LP_TOKENS_SUCCESS,
    payload: data,
});
export const SetStakingLPTokensError = (data) => ({
    type: SET_STAKING_LP_TOKENS_ERROR,
    payload: data,
});
