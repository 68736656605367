import {
    GET_POOL_RESERVES,
    GET_POOL_RESERVES_SUCCESS,
    GET_POOL_RESERVES_ERROR,
    GET_POOL_BY_ADDRESS,
    GET_TOKEN_BY_ADDRESS,
    GET_RESERVES_TOKEN,
    GET_WITHDRAW_POOL_RESERVES_SUCCESS,
    GET_RECEIVE_WITHDRAW_TOKEN_LIST,
    FETCH_WITHDRAW_POOLS,
    FETCH_WITHDRAW_POOLS_SUCCESS,
    FETCH_WITHDRAW_POOLS_ERROR,
    GET_SYNCING_LP,
    GET_ALLOWED_LIST,
    GET_PL_NATIVE_ASSETS_LIST,
} from './actionTypes';
import { axiosApiVirtuSwap } from 'helpers/api_helper';
import {
    FETCH_POOL_RESERVES,
    FETCH_WITHDRAW_POOLS_LIST,
} from 'helpers/url_helper';
import { defaultChainId } from 'components/DummyData';

//get poolReserves
export const getHandlePoolReserves = (chainId, addresses, cb = () => {}, payload) => {
    return async (dispatch) => {
        try {
            chainId ??= defaultChainId;
            dispatch(getPoolReserves(true));
            if (!payload) {
                const func = (address) =>
                    axiosApiVirtuSwap.get(
                        FETCH_POOL_RESERVES + '?poolAddress=' + address + '&chainId=' + chainId,
                        payload
                    );
                await Promise.all(addresses?.map((add) => func(add)))
                    .then((res) => {
                        if (res) {
                            dispatch(getPoolReservesSuccess(res));
                            cb(res);
                        }
                    })
                    .catch((err) => {
                        dispatch(getPoolReservesError(err.message));
                    });
            } else {
                const res = await axiosApiVirtuSwap.get(
                    FETCH_POOL_RESERVES + '?poolAddress=' + addresses + '&chainId=' + chainId,
                    payload
                );
                if (res.data) {
                    dispatch(handleWithdrawPoolByAddress(res.data));
                }
            }
        } catch (err) {
            dispatch(getPoolReservesError(err.message));
        }
    };
};

//Fetch Withdraw Pools
export const handleFetchWithdrawPools = (chainId, address, cb = () => {}) => {
    return async (dispatch) => {
        try {
            chainId ??= defaultChainId;
            dispatch(fetchWithdrawPools(true));
            const res = await axiosApiVirtuSwap.get(
                FETCH_WITHDRAW_POOLS_LIST + '?poolAddress=' + address + '&chainId=' + chainId,
            );
            if (res.data) {
                //format numbers

                dispatch(fetchWithdrawPoolsSuccess(res.data));
                cb(res.data);
            }
        } catch (err) {
            dispatch(fetchWithdrawPoolsError(err.message));
        }
    };
};

////get allowed list in add liquidity
export const handleFetchAllowedList = (
    address,
    ProfileToken,
    PoolInfoReserved,
    plNativeList,
    payload
) => {
    return async (dispatch) => {
        try {
            let i_Token = [];
            let CurrentList = plNativeList.concat(PoolInfoReserved);
            await Promise.all(address?.map((add) => add)).then((status) => {
                if (!!status.length) {
                    var filteredTokens = status.filter(
                        (add1) =>
                            !CurrentList?.some((add2) => add1 === add2.address)
                    );
                    if (!!filteredTokens.length) {
                        filteredTokens?.map((i) => {
                            let token = i?.toLowerCase();
                            return i_Token.push(
                                ProfileToken?.find(
                                    (pt) => token === pt.address?.toLowerCase()
                                )
                            );
                        });
                    }
                }
                if (!!i_Token.length) {
                    dispatch(getAllowedList(i_Token));
                }
            });
        } catch (err) {
            console.log(err.message);
        }
    };
};

//get reserves Tokens
export const getReservesTokens = (addresses) => {
    return async (dispatch) => {
        dispatch(fetchReservesTokens(addresses));
    };
};
//get poolReserves
export const getPoolReserves = (data) => ({
    type: GET_POOL_RESERVES,
    payload: data,
});
export const getPoolReservesSuccess = (data) => ({
    type: GET_POOL_RESERVES_SUCCESS,
    payload: data,
});
export const getPoolReservesError = (data) => ({
    type: GET_POOL_RESERVES_ERROR,
    payload: data,
});

//get pool by address
export const getPoolByAddress = (data) => ({
    type: GET_POOL_BY_ADDRESS,
    payload: data,
});

//get token by address
export const getTokenByAddress = (data) => ({
    type: GET_TOKEN_BY_ADDRESS,
    payload: data,
});

//get reserves Tokens
export const fetchReservesTokens = (data) => ({
    type: GET_RESERVES_TOKEN,
    payload: data,
});

//get withdraw pool by address
export const handleWithdrawPoolByAddress = (data) => ({
    type: GET_WITHDRAW_POOL_RESERVES_SUCCESS,
    payload: data,
});

//get Receive withdraw Tokens list
export const getReceiveWithdrawTokensList = (data) => ({
    type: GET_RECEIVE_WITHDRAW_TOKEN_LIST,
    payload: data,
});

//Fetch Withdraw Pools
export const fetchWithdrawPools = (data) => ({
    type: FETCH_WITHDRAW_POOLS,
    payload: data,
});

export const fetchWithdrawPoolsSuccess = (data) => ({
    type: FETCH_WITHDRAW_POOLS_SUCCESS,
    payload: data,
});

export const fetchWithdrawPoolsError = (data) => ({
    type: FETCH_WITHDRAW_POOLS_ERROR,
    payload: data,
});

//get Syncing your LP
export const handleGetSyncingLP = (data) => ({
    type: GET_SYNCING_LP,
    payload: data,
});

//get allowed list in add liquidity
export const getAllowedList = (data) => ({
    type: GET_ALLOWED_LIST,
    payload: data,
});

//get Native assets list in add liquidity
export const getPLNativeAssetsList = (data) => ({
    type: GET_PL_NATIVE_ASSETS_LIST,
    payload: data,
});
