import {
    FETCH_POOLS_INFO,
    FETCH_POOLS_INFO_SUCCESS,
    FETCH_POOLS_INFO_ERROR,
    FETCH_POOLS_INFO_RESERVED,
    SET_POOLS_INFO_GRAPH_LIST,
    FETCH_POOLS_FEATURED,
    FETCH_POOLS_FEATURED_SUCCESS,
    FETCH_POOLS_FEATURED_ERROR,
} from './actionType';

const initialStates = {
    Pools_Info: [],
    pool_Loader: false,
    Pool_Info_Reserved: [],
    PoolInfoGraphListMapping: {},
    PoolsInfoFeaturedMapping: {},
};

export const PoolsInfoReducer = (state = initialStates, action) => {
    const { type, payload } = action;
    switch (type) {
        //Fetch Pools Info
        case FETCH_POOLS_INFO:
            return {
                ...state,
                pool_Loader: false,
            };
        case FETCH_POOLS_INFO_SUCCESS:
            return {
                ...state,
                Pools_Info: payload,
                pool_Loader: true,
            };
        case FETCH_POOLS_INFO_ERROR:
            return {
                ...state,
                pool_Loader: false,
                Pools_Info: [],
            };
        //Fetch Pool Info Reserved
        case FETCH_POOLS_INFO_RESERVED:
            return {
                ...state,
                Pool_Info_Reserved: payload,
            };
        //Set Pool Info Chart Data List
        case SET_POOLS_INFO_GRAPH_LIST:
            return {
                ...state,
                PoolInfoGraphListMapping: {
                    ...state.PoolInfoGraphListMapping,
                    ...payload,
                },
            };
        //Fetch Pools List Featured
        case FETCH_POOLS_FEATURED:
            return {
                ...state,
                pool_Loader: false,
            };
        case FETCH_POOLS_FEATURED_SUCCESS:
            return {
                ...state,
                PoolsInfoFeaturedMapping: {
                    ...state.PoolsInfoFeaturedMapping,
                    ...payload,
                },
                pool_Loader: true,
            };
        case FETCH_POOLS_FEATURED_ERROR:
            return {
                ...state,
                pool_Loader: false,
            };
        default:
            return state;
    }
};

export default PoolsInfoReducer;
