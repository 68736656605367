import { ethers } from 'ethers';
import { Reserve } from './Reserve';

export class RawPool {
    readonly poolAddress?: string;
    readonly token0?: string;
    readonly token1?: string;
    readonly balance0?: ethers.BigNumberish;
    readonly balance1?: ethers.BigNumberish;
    readonly blocksDelay?: number;
    readonly lastSwapBlock?: number;
    readonly fee?: number;
    readonly vFee?: number;
    readonly maxReserveRatio?: ethers.BigNumberish;
    readonly reserveRatio?: number;
    readonly allowList?: Array<string>;
    readonly reserves?: Array<Reserve>;
    readonly reservesBaseValues?: Array<Reserve>;

    hasCommonTokenWith(pool: RawPool) {
        return (
            this.token0?.toLowerCase() == pool.token0?.toLowerCase() ||
            this.token1?.toLowerCase() == pool.token0?.toLowerCase() ||
            this.token0?.toLowerCase() == pool.token1?.toLowerCase() ||
            this.token1?.toLowerCase() == pool.token1?.toLowerCase()
        );
    }

    isBlockedForVirtualTrading(blockNumber: number) {
        return blockNumber < (this.blocksDelay ?? 0) + this.lastSwapBlock!;
    }

    hasTokenWithAddress(tokenAddress: string) {
        return (
            this.token0?.toLowerCase() == tokenAddress?.toLowerCase() ||
            this.token1?.toLowerCase() == tokenAddress?.toLowerCase()
        );
    }

    allowsTokenAsReserve(tokenAddress: string) {
        return (
            this.allowList
                ?.map((value) => value.toLowerCase())
                .includes(tokenAddress?.toLowerCase()) ?? false
        );
    }
}
