import Polygon from 'assets/images/Polygon_(MATIC).png';
import Ethereum from 'assets/images/Ethereum_(ETH).png';
import {
    customGraphTooltip,
    customGraphBarTooltip,
    handleThousandSeparator,
} from 'components/common/CommonFunctions';

//Token Block
export const tokens = [
    {
        id: 1,
        value: 1,
    },
    {
        id: 2,
        value: 2,
    },
];
export const defaultChainId = Number(process.env.REACT_APP_DEFAULT_NETWORK_ID);

//PoolDetail
export const graphOneSeries = [
    {
        name: [
            'series-1',
            'series-2',
            'series-3',
            'series-4',
            'series-5',
            'series-6',
            'series-7',
            'series-8',
            'series-9',
            'series-10',
            'series-11',
            'series-12',
            'series-13',
            'series-14',
            'series-15',
            'series-16',
            'series-17',
            'series-18',
            'series-19',
        ],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
];
export const graphTwoSeries = [
    {
        name: [
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
            'series-2',
        ],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
];

//CurrencyGraph
export const GraphOneOption = [
    { id: 'first', name: 'Volume', value: 1, graphList: graphOneSeries },
    { id: 'second', name: 'TVL', value: 2, graphList: graphOneSeries },
    // { id: 'third', name: 'Liquidity', value: 3, graphList: graphOneSeries },
    { id: 'four', name: 'Fees', value: 4, graphList: graphOneSeries },
];

//DurationGraph
export const GraphSecondOption = [
    {
        id: 'first1',
        name: '1D',
        value: 1,
        type: '1Day',
        graphList: graphTwoSeries,
    },
    {
        id: 'second1',
        name: '1W',
        value: 2,
        type: '1Week',
        graphList: graphTwoSeries,
    },
    {
        id: 'third1',
        name: '1M',
        value: 3,
        type: '1Month',
        graphList: graphTwoSeries,
    },
    {
        id: 'four1',
        name: '3M',
        value: 4,
        type: '3Months',
        graphList: graphTwoSeries,
    },
    {
        id: 'five1',
        name: '1Y',
        value: 5,
        type: '1Year',
        graphList: graphTwoSeries,
    },
    {
        id: 'six1',
        name: 'All',
        value: 6,
        type: 'all',
        graphList: graphTwoSeries,
    },
];

//Pools Info
export const poolInfoHeading = [
    { id: 1, name: '', sortable: false },
    { id: 2, name: 'Native assets', sortable: true },
    { id: 3, name: 'TVL', sortable: true },
    { id: 4, name: 'Liquidity', sortable: false },
    { id: 5, name: 'Reserves', sortable: true },
    { id: 6, name: 'Min APR', sortable: true },
    { id: 7, name: 'Avg APR', sortable: true },
    { id: 8, name: '', sortable: false },
];

//LiquidityPool
export const liquidityHeading = [
    { id: 1, name: 'Native assets', shortLabel: 'ascending' },
    { id: 2, name: 'My position', shortLabel: 'descending' },
    { id: 3, name: 'My TVL', shortLabel: 'descending' },
    { id: 4, name: 'Pool TVL', shortLabel: 'descending' },
    { id: 5, name: 'Reserves', shortLabel: 'descending' },
    { id: 6, name: 'Actions', shortLabel: 'descending' },
];

//TransactionSettingModal
export const slippageToleranceValue = [
    { slippage: 0.1, value: 1 },
    { slippage: 0.5, value: 2 },
    { slippage: 1, value: 3 },
];

//Header
export const dropDownToken = [
    {
        name: 'Ethereum',
        icon: Ethereum,
        value: '0x1',
    },
    {
        name: 'Polygon',
        icon: Polygon,
        value: '0x13881',
    },
    {
        name: 'Ropsten',
        icon: Ethereum,
        value: '0x3',
    },
];
export const navOptions = [
    {
        id: 1,
        name: 'Trade',
        path: '/',
        isVisible: process.env.REACT_APP_NAV_OPTION_TRADE === 'true',
    },
    {
        id: 2,
        name: 'Liquidity',
        path: '/liquidity',
        isVisible: process.env.REACT_APP_NAV_OPTION_LIQUIDITY === 'true',
    },
    {
        id: 3,
        name: 'Pools',
        path: '/pools',
        isVisible: process.env.REACT_APP_NAV_OPTION_POOLS === 'true',
    },
    {
        id: 4,
        name: 'Staking',
        path: '/staking',
        isVisible: process.env.REACT_APP_NAV_OPTION_STAKING === 'true',
    },
    {
        id: 5,
        name: 'CEX on-ramp',
        path: '/cexOnRamp',
        isVisible: process.env.REACT_APP_NAV_OPTION_CEX_ON_RAMP === 'true',
    },
];

//GraphInfo
export const options = {
    colors: ['#f15223'],
    chart: {
        foreColor: 'transparent',
        background: 'transparent',
        toolbar: {
            show: false,
        },
    },
    xaxis: {
        axisTicks: {
            show: true,
        },
        axisBorder: {
            show: false,
        },
        max: '150px',
        crosshairs: {
            show: true,
            stroke: {
                color: '#f15223',
                width: 1,
                dashArray: 0,
            },
        },
        type: 'datetime',
        labels: {
            style: {
                colors: 'var(--system-color-600)',
            },
        },
    },
    yaxis: {
        show: true,
        axisTicks: {
            show: true,
        },
        labels: {
            style: {
                colors: 'var(--system-color-600)',
            },
            formatter: function (value) {
                return (
                    '$' +
                    handleThousandSeparator(
                        Number.parseFloat(Number(value)?.toFixed(2))
                    )
                );
            },
        },
        title: {
            style: {
                color: 'var(--system-color-600)',
            },
        },
    },
    tooltip: {
        enabled: true,
        fillSeriesColor: true,
        theme: true,
        x: {
            show: false,
        },
        marker: {
            show: false,
        },
        custom: customGraphTooltip,
    },
    grid: {
        show: false,
    },
    dataLabels: {
        enabled: false,

        background: {
            enabled: false,
        },
    },
    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 6,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 70, 100],
        },
    },
    stroke: {
        width: [2, 2],
        curve: 'straight',
    },
    noData: {
        text: 'No data available',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: 'var(--system-color-500)',
            fontSize: '14px',
            fontFamily: 'Plus Jakarta Sans',
        },
    },
};
export const barOptions = {
    colors: ['#f15223'],
    chart: {
        id: 'apexchart-example',
        foreColor: 'transparent',
        background: 'transparent',
        toolbar: {
            show: false,
        },
        // sparkline: {
        //     enabled: true,
        // },
        zoom: {
            enabled: false,
        },
    },
    xaxis: {
        show: true,
        axisTicks: {
            show: true,
        },
        axisBorder: {
            show: false,
        },
        type: 'datetime',
        labels: {
            style: {
                colors: 'var(--system-color-600)',
            },
        },
    },
    yaxis: {
        show: true,
        axisTicks: {
            show: true,
        },
        labels: {
            style: {
                colors: 'var(--system-color-600)',
            },
            formatter: function (value) {
                return (
                    '$' +
                    handleThousandSeparator(
                        Number.parseFloat(Number(value)?.toFixed(2))
                    )
                );
            },
        },
        title: {
            style: {
                color: 'var(--system-color-600)',
            },
        },
    },
    tooltip: {
        enabled: true,
        fillSeriesColor: true,
        theme: true,
        x: {
            show: false,
        },
        marker: {
            show: false,
        },
        custom: customGraphBarTooltip,
    },
    grid: {
        show: false,
    },
    dataLabels: {
        enabled: false,
        background: {
            enabled: false,
        },
    },
    stroke: {
        width: [2, 2],
        curve: 'straight',
    },
    noData: {
        text: 'No data available',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: 'var(--system-color-500)',
            fontSize: '14px',
            fontFamily: 'Plus Jakarta Sans',
        },
    },
    plotOptions: {
        bar: {
            horizontal: false,
            barWidth: 100,
        },
    },
    fill: {
        type: 'gradient',
        gradient: {
            type: 'vertical',
            shadeIntensity: 0.5,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 70, 100],
        },
    },
};
export const hiddenAxisOption = {
    show: false,
    labels: {
        show: false,
    },
    axisBorder: {
        show: false,
    },
    axisTicks: {
        show: false,
    }
};

//TradeRouter
export const boxStyle = {
    lineColor: '#f15223',
    startAnchor: 'right',
    endAnchor: 'left',
    headColor: '#f15223',
    tailColor: '#f15223',
    strokeWidth: 1,
    showHead: true,
    showTail: false,
    curveness: 1,
    dashness: {
        strokeLen: 4,
        nonStrokeLen: 3,
        animation: false,
    },
};
export const box2Style = {
    lineColor: '#f15223',
    startAnchor: 'left',
    endAnchor: 'right',
    headColor: '#f15223',
    tailColor: '#f15223',
    strokeWidth: 1,
    showHead: false,
    showTail: true,
    curveness: 1,
    dashness: {
        strokeLen: 4,
        nonStrokeLen: 3,
        animation: false,
    },
};
export const box3Style = {
    lineColor: '#f15223',
    startAnchor: 'right',
    endAnchor: 'left',
    headColor: '#f15223',
    tailColor: '#f15223',
    strokeWidth: 1,
    showHead: true,
    showTail: false,
    curveness: 0,
    dashness: {
        strokeLen: 4,
        nonStrokeLen: 3,
        animation: false,
    },
};

export const box4Style = {
    lineColor: '#f15223',
    startAnchor: 'right',
    endAnchor: 'left',
    headColor: '#f15223',
    tailColor: '#f15223',
    strokeWidth: 1,
    showHead: true,
    showTail: false,
    curveness: 0.5,
    dashness: {
        strokeLen: 4,
        nonStrokeLen: 3,
        animation: false,
    },
};
export const box5Style = {
    lineColor: '#f15223',
    startAnchor: 'left',
    endAnchor: 'right',
    headColor: '#f15223',
    tailColor: '#f15223',
    strokeWidth: 1,
    showHead: false,
    showTail: true,
    curveness: 0.5,
    dashness: {
        strokeLen: 4,
        nonStrokeLen: 3,
        animation: false,
    },
};

//Tooltip
export const customCss = {
    background: 'var(--system-color-400)',
    color: 'var(--system-color-600)',
    border: 'var(--system-color-400)',
    padding: 10,
    radius: 5,
};
export const customLiquidityCss = {
    background: 'var(--system-color-200)',
    color: 'var(--system-color-600)',
    border: 'var(--system-color-400)',
    radius: 12,
};
export const customBoostedCss = {
    background: 'var(--system-color-200)',
    color: 'var(--system-color-600)',
    border: 'var(--system-color-400)',
    radius: 10,
    padding: 10,
    arrow: 0,
    offset: 5,
};
//Liquidity WithdrawSuccess
export const rangeOptions = [
    { id: 1, name: 25 },
    { id: 2, name: 50 },
    { id: 3, name: 75 },
    { id: 4, name: 'max' },
];

//DAOStake
export const stakeListSimple = [
    {
        id: -1,
        title: 'VRSW',
        block_desc: [
            {
                id: 1,
                block_title: '',
            },
            {
                id: 2,
                block_title: 'My stake',
                block_data: '0 VRSW',
                tooltip: '',
                block_data_tooltip: '',
                cellAmount: 0,
                value: 'mystake',
                class_name: 'vrsw_col_1 text-end',
            },
            {
                id: 3,
                block_title: 'Unlocked',
                block_data: '0',
                tooltip: '',
                block_data_tooltip: '',
                value: 'freeValue',
                class_name: 'vrsw_col_4 text-end',
            },
            {
                id: 4,
                block_title: 'Total staked',
                block_data: '0 VRSW',
                tooltip: '',
                block_data_tooltip: '',
                cellAmount: 0,
                value: 'totalStaked',
                class_name: 'vrsw_col_2 text-end',
            },
            {
                id: 5,
                block_title: 'Avg. / Possible LP APR',
                tooltip: '',
                class_name: 'vrsw_col_2 text-end',
                block_desc: [
                    {
                        id: 5.1,
                        block_title: 'Average LP APR',
                        block_data: '0 VRSW',
                        tooltip: '',
                        block_data_tooltip: 'Average LP APR',
                        cellAmount: 0,
                        value: 'averageLpApr',
                        class_name: 'vrsw_col_2 text-end',
                        notInUsdAmount: true,
                    },{
                        id: 5.15,
                        block_title: 'divider',
                        block_data: '/',
                        tooltip: '',
                        block_data_tooltip: '',
                        cellAmount: '/',
                        value: null,
                        class_name: 'vrsw_col_2 text-center',
                        notInUsdAmount: true,
                    },
                    {
                        id: 5.2,
                        block_title: 'Possible LP APR',
                        block_data: '0 VRSW',
                        tooltip: '',
                        block_data_tooltip: 'Possible LP APR',
                        cellAmount: 0,
                        value: 'possibleAverageLpApr',
                        class_name: 'vrsw_col_2 text-end',
                        notInUsdAmount: true,
                    },
                ],
            },
            {
                id: 6,
                block_title: 'VRSW staking APR',
                block_data: '0 VRSW',
                tooltip: 'VRSW APR if VRSW locked for 144 weeks',
                block_data_tooltip: '',
                cellAmount: 0,
                value: 'possibleApr',
                class_name: 'vrsw_col_5 text-end',
                notInUsdAmount: true,
            },
            {
                id: 7,
                block_title: 'Claimable rewards',
                block_data: '0 VRSW',
                tooltip: '',
                block_data_tooltip: '',
                cellAmount: 0,
                value: 'claimable',
                class_name: 'vrsw_col_5 text-end',
            },
            {
                id: 8,
                block_title: '',
            },
        ],
    },
];

//DAOStake
export const virtuReport = [
    {
        id: 1,
        name: 'Total value of staked VRSW:',
        value: 0,
        type: 'total_staked_vrsw',
        postFix: '',
        isOptional: false,
    },
    {
        id: 2,
        name: 'Number of staked VRSW positions:',
        value: 0,
        icon: '',
        type: 'number_of_staked_positions',
        postFix: '',
        isOptional: false,
    },
    {
        id: 3,
        name: 'Weighted average staking duration:',
        value: 0,
        icon: '',
        type: 'weighted_avg_staking_duration_in_days',
        postFix: ' days',
        isOptional: false,
    },
    {
        id: 4,
        name: 'Total value of locked VRSW:',
        value: 0,
        icon: '',
        type: 'total_locked_vrsw',
        postFix: '',
        isOptional: false,
    },
    {
        id: 5,
        name: 'Number of locked VRSW positions:',
        value: 0,
        icon: '',
        type: 'number_of_locked_positions',
        postFix: '',
        isOptional: false,
    },
    {
        id: 6,
        name: 'Weighted average lock period:',
        value: '0',
        icon: '',
        type: 'weighted_avg_locking_duration_in_days',
        postFix: ' days',
        isOptional: false,
    },
    {
        id: 7,
        name: 'Governance power:',
        value: '0',
        icon: '',
        type: 'governancePower',
        postFix: '%',
        isOptional: true,
    },
];

//PositionBD
export const stakedLPHeadSimple = [
    {
        id: 1,
        block_title: '',
        class_name: 'setCol1',
    },
    {
        id: 2,
        block_title: 'My balance',
        tooltip: '',
        class_name: 'setCol2',
    },
    {
        id: 3,
        block_title: 'Pool value',
        tooltip: '',
        class_name: 'setCol3',
    },
    {
        id: 4,
        block_title: 'Average pool APR',
        tooltip: '',
        class_name: 'setCol4',
    },
    {
        id: 8,
        block_title: 'My APR',
        tooltip: '',
        class_name: 'setCol5',
    },
    {
        id: 9,
        block_title: 'Possible APR',
        tooltip: 'APR if VRSW staked',
        class_name: 'setCol7',
    },
    {
        id: 6,
        block_title: 'Claimable rewards',
        tooltip: '',
        class_name: 'setCol8',
    },
    {
        id: 7,
        block_title: '',
        class_name: 'setCol6',
    },
];
export const unStakedLPHeadSimple = [
    {
        id: 1,
        block_title: '',
        class_name: 'setCol1',
    },
    {
        id: 2,
        block_title: 'My balance',
        tooltip: '',
        class_name: 'setCol2',
    },
    {
        id: 3,
        block_title: 'Pool value',
        tooltip: '',
        class_name: 'setCol3',
    },
    {
        id: 4,
        block_title: 'Average pool APR',
        tooltip: '',
        class_name: 'setCol4',
    },
    {
        id: 8,
        block_title: 'My APR',
        tooltip: '',
        class_name: 'setCol5',
    },
    {
        id: 9,
        block_title: 'Possible APR',
        tooltip: 'APR if LP tokens and VRSW staked',
        class_name: 'setCol8',
    },
    {
        id: 6,
        block_title: 'Claimable rewards',
        tooltip: '',
        class_name: 'setCol7',
    },
    {
        id: 7,
        block_title: '',
        class_name: 'setCol6',
    },
];

//PositionBD
export const BoostedHead = [
    { id: 1, title: 'Asset' },
    { id: 3, title: 'APR' },
];
export const ClaimableHead = [
    { id: 1, title: 'Asset' },
    { id: 2, title: 'Claimable' },
    { id: 3, title: '' },
];

//Staking Page
export const isVRSW_Stake_Collapse = [
    {
        locked: true,
        block_desc: [
            {
                id: 1,
                title: 'Deposited on',
                value: '-',
            },
            {
                id: 2,
                title: 'Deposit value',
                value: `0 VRSW`,
            },
            {
                id: 3,
                title: 'Unlock date',
                value: `-`,
            },
        ],
    },
];
//Staking page
export const fillColors = [
    '#F15223',
    '#F1D023',
    '#50AF95',
    '#00A3FF',
    '#C547E5',
];

//PoolReservesBlock
export const PlReserveHead = [
    { id: 1, name: 'Name' },
    { id: 2, name: 'Balance and value' },
    { id: 3, name: '% of TVL' },
];
