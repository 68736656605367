/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-extend-native */
import { ethers, constants } from 'ethers';
import { Decimal } from 'decimal.js-light';
import _, { isObject } from 'lodash';
import {
    Router,
    Token as RouterToken,
    SwapType,
} from '@virtuswap/v1-sdk';
import store from '../store/index';
import { BigNumber } from 'ethers';
import { RawPool } from 'router/entities/RawPool';
import { Token } from 'router/entities/Token';
import { plainToClass } from 'class-transformer';
import { handleDecimal } from 'components/common/CommonFunctions';
import { axiosApiVirtuSwap } from 'helpers/api_helper';
import { GET_LP_BALANCES } from 'helpers/url_helper';
import { defaultChainId } from 'components/DummyData';

Decimal.set({ precision: 30 });

const web3Service = require('./web3Service').default;

function getSubperiodLength(period, from = 0, to = 1) {
    switch (period) {
        case '1D':
            return 1 * 60 * 60 * 1000;
        case '1W':
            return 4 * 60 * 60 * 1000;
        case '1M':
            return 24 * 60 * 60 * 1000;
        case '3M':
            return 2 * 24 * 60 * 60 * 1000;
        case '1Y':
            return 8 * 24 * 60 * 60 * 1000;
        default:
            return Math.max((to - from) / 45, 24 * 60 * 60 * 1000);
    }
}

function getSubperiodsNumber(period) {
    switch (period) {
        case '1D':
            return 24;
        case '1W':
            return 42;
        case '1M':
            return 30;
        case '3M':
            return 45;
        case '1Y':
            return 45;
        default:
            return 45;
    }
}

Number.prototype.toFixedNumber = function (digits, base) {
    var pow = Math.pow(base || 10, digits);
    return Math.round(this * pow) / pow;
};

const fee = 0.003;
const reverseFee = 1 - fee;

const poolsCache = new WeakMap();
const tokensCache = new WeakMap();
export default {
    getRawPoolsFromStore() {
        const rawPools = store.getState().Trade.RawPools;
        if (!poolsCache.has(rawPools)) {
            const pools = rawPools.map((p) => plainToClass(RawPool, p));
            poolsCache.set(rawPools, pools);
        }
        return poolsCache.get(rawPools);
    },
    getTokensFromStore() {
        const rawTokens = store.getState().Trade.selectedChain?.part === 1
            ? store.getState().Trade.ProfileToken1
            : store.getState().Trade.ProfileToken2;
        if (!tokensCache.has(rawTokens)) {
            const tokens = rawTokens.map((t) => plainToClass(Token, t));
            tokensCache.set(rawTokens, tokens);
        }
        return tokensCache.get(rawTokens);
    },

    getPoolFromStore(address) {
        address = address?.toString()?.toLowerCase();
        let pools = this.getRawPoolsFromStore();
        return pools.find((p) => p.poolAddress === address);
    },
    getPoolFromStoreByTokens(token0, token1) {
        token0 = token0?.toString()?.toLowerCase();
        token1 = token1?.toString()?.toLowerCase();
        let pools = this.getRawPoolsFromStore();
        return pools.find(
            (p) =>
                (p.token0 === token0 && p.token1 === token1) ||
                (p.token0 === token1 && p.token1 === token0)
        );
    },
    formatUnits(amount, decimals) {
        if (typeof amount === 'string' || typeof amount === 'number') {
            amount = ethers.utils.parseUnits(String(amount), decimals);
        }
        if (!ethers.BigNumber.isBigNumber(amount)) {
            return 0;
        }
        if (!isObject(amount)) amount = BigNumber.from(String(amount));
        if (isNaN(amount)) return 0;
        if (decimals === undefined) decimals = 18;
        return ethers.utils.formatUnits(amount, decimals);
    },
    parseUnits(amount, decimals) {
        return ethers.utils.parseUnits(amount, decimals);
    },

    getRpcUrl(chainId) {
        const { currentChainId, ChainList } = store.getState().Trade;
        chainId ??= currentChainId ?? defaultChainId;

        const chainInfo = ChainList.find(chain => chain.id === chainId);
        return chainInfo?.rpc_url;
    },

    getNativeCurrency(chainId) {
        const { currentChainId, ChainList } = store.getState().Trade;
        chainId ??= currentChainId ?? defaultChainId;

        const chainInfo = ChainList.find(chain => chain.id === chainId);
        return chainInfo?.native_currency ?? 'ETH';
    },

    getTokenInfo(token) {
        token = token?.toString();
        let tokens = store
            .getState()
            .Trade.ProfileToken1.concat(store.getState().Trade.ProfileToken2);
        return tokens.find((t) => t.address === token) ?? null;
    },

    getTokenDecimal(token) {
        token = token?.toString();
        let tokens = store
            .getState()
            .Trade.ProfileToken1.concat(store.getState().Trade.ProfileToken2);
        let tokenInfo = tokens.find((t) => t.address === token);
        if (!tokenInfo) return 18;
        else return tokenInfo.decimals;
    },

    getDecimalsForToken(pool, token) {
        token = token?.toString();
        if (pool.token0 === token) return pool.decimals0;
        if (pool.token1 === token) return pool.decimals1;
        return 18;
    },

    getBalanceForTokenBN(pool, token) {
        token = token?.toString()?.toLowerCase();
        if (pool.token0 === token) return pool.balance0;
        if (pool.token1 === token) return pool.balance1;
        return 0;
    },

    getBalanceNotForToken(pool, token) {
        token = token?.toString();
        if (pool.token0 === token) return pool.balance1;
        if (pool.token1 === token) return pool.balance0;
        return 0;
    },

    getBalanceNotForTokenBN(pool, token) {
        token = token?.toString()?.toLowerCase();
        if (pool.token0 === token) return pool.balance0;
        if (pool.token1 === token) return pool.balance1;
        return 0;
    },

    getBalanceForDirectedPool(pool, token) {
        token = token?.toString();
        if (pool.token0.address === token) return pool.balance0;
        if (pool.token1.address === token) return pool.balance1;
        return 0;
    },

    getBalanceForToken(pool, token0Address) {
        token0Address = token0Address?.toString();
        let token0Balance = 0;
        let token1Balance = 0;

        if (
            pool.hasOwnProperty('token0Balance') &&
            pool.hasOwnProperty('token1Balance')
        ) {
            token0Balance = pool.token0Balance;
            token1Balance = pool.token1Balance;
        } else if (
            pool.hasOwnProperty('balance0') &&
            pool.hasOwnProperty('balance1')
        ) {
            token0Balance = pool.balance0;
            token1Balance = pool.balance1;
        }

        if (pool.token0 === token0Address)
            return parseFloat(token0Balance.toString());
        if (pool.token1 === token0Address)
            return parseFloat(token1Balance.toString());

        return 0;
    },

    calculateVirtualTradeMetrics(
        amountIn,
        vLiqA,
        vLiqC,
        jkLiqA,
        jkLiqB,
        ikLiqC,
        ikLiqB
    ) {
        const impliedAmountOutV = amountIn * (vLiqC / vLiqA);

        const amountOutV =
            (amountIn * reverseFee * vLiqC) / (amountIn * reverseFee + vLiqA);

        const tradeCostV = impliedAmountOutV - amountOutV;

        const impliedAmountOutU =
            amountIn * (ikLiqB / ikLiqC) * (jkLiqA / jkLiqB);

        const output1 =
            (amountIn * reverseFee * ikLiqB) / (ikLiqC + amountIn * reverseFee);

        const amountOutU =
            (output1 * reverseFee * jkLiqA) / (output1 * reverseFee + jkLiqB);

        const TradeCostU = impliedAmountOutU - amountOutU;

        let vSwapSavings = ((TradeCostU - tradeCostV) / TradeCostU) * 100;

        const priceImpact =
            (impliedAmountOutV - amountOutV) / impliedAmountOutV - fee;

        if (vSwapSavings < 0) vSwapSavings = 0;

        return {
            impliedAmountOutV,
            amountOutV,
            tradeCostV,
            impliedAmountOutU,
            output1,
            amountOutU,
            TradeCostU,
            vSwapSavings,
            priceImpact,
        };
    },

    calculateTriangularPriceImpact(
        amountIn,
        amountOutLeg1,
        amountOutLeg2,
        poolABalance0,
        poolABalance1,
        poolBBalance0,
        poolBBalance1,
        fee
    ) {
        const feeWithImpact =
            1 -
            amountOutLeg2 /
                (amountIn *
                    (poolABalance1 / poolABalance0) *
                    (poolBBalance1 / poolBBalance0));

        const totalFee =
            (amountIn * fee +
                amountOutLeg1 * (poolABalance0 / poolABalance1) * fee) /
            amountIn;

        const priceImpact = feeWithImpact - totalFee;

        return { feeWithImpact, totalFee, priceImpact };
    },

    calculateNativeTradeMetrics(amountIn, vLiqA, vLiqB) {
        let impliedAmountOutV = amountIn * (vLiqB / vLiqA);

        let amountOutV =
            (amountIn * reverseFee * vLiqB) / (amountIn * reverseFee + vLiqA);

        let priceImpact =
            (impliedAmountOutV - amountOutV) / impliedAmountOutV - fee;

        return {
            impliedAmountOutV,
            amountOutV,
            priceImpact,
        };
    },

    async setRouterERC20Approval(tokenAddress) {
        tokenAddress = tokenAddress?.toString();
        return await web3Service.setRouterERC20Approval(tokenAddress);
    },

    async getLPStakerAllowance(lpToken) {
        let allowance = await web3Service.getLPtakerAllowance(lpToken);
        return ethers.utils.formatUnits(allowance, 18);
    },

    async getVRSWStakerAllowance() {
        let vrswAllowance = await web3Service.getVRSWStakerAllowance();
        let adjusted = await ethers.utils.formatUnits(vrswAllowance, 18);
        return adjusted;
    },
    async getgVRSWStakerAllowance() {
        let gvrswAllowance = web3Service.getgVRSWStakerAllowance();
        let adjusted = ethers.utils.formatUnits(gvrswAllowance, 18);
        return adjusted;
    },

    async showApproveButton(tokenAddress, amount) {
        tokenAddress = tokenAddress?.toString();
        let tokenDecimals = this.getTokenDecimal(tokenAddress);

        let showApprove = await web3Service.showApproveButton(
            tokenAddress,
            amount,
            tokenDecimals
        );

        return showApprove;
    },

    async quote(tokenIn, tokenOut, amountIn, guid) {
        tokenIn = tokenIn?.toString();
        tokenOut = tokenOut?.toString();
        let tempamountIn = ethers.utils
            .parseUnits(
                new Decimal(amountIn).toFixed(this.getTokenDecimal(tokenIn)),
                this.getTokenDecimal(tokenIn)
            )
            .toString();

        let res = await web3Service.quote(
            tokenIn,
            tokenOut,
            tempamountIn,
            guid
        );
        res.amountOut = parseFloat(
            ethers.utils.formatUnits(
                res.amountOut,
                this.getTokenDecimal(tokenOut)
            )
        );

        res.amountIn = handleDecimal(amountIn);
        return res;
    },

    async getERC20Allowance(spender, tokenAddress) {
        tokenAddress = tokenAddress?.toString();
        let allowance = await web3Service.getERC20Allowance(
            spender,
            tokenAddress
        );
        let tokenDecimals = this.getTokenDecimal(tokenAddress);
        return ethers.utils.formatUnits(allowance, tokenDecimals);
    },
    async removeLiquidity(
        token0Address,
        token1Address,
        amountToWithdraw,
        amount0Desired,
        amount1Desired
    ) {
        let decimals0 = this.getTokenDecimal(token0Address);
        let decimals1 = this.getTokenDecimal(token1Address);

        let amount0 = ethers.utils.parseUnits(
            new Decimal(amount0Desired).toFixed(decimals0),
            decimals0
        );

        let amount1 = ethers.utils.parseUnits(
            new Decimal(amount1Desired).toFixed(decimals1),
            decimals1
        );

        return await web3Service.removeLiquidity(
            token0Address,
            token1Address,
            amount0.mul(98).div(100),
            amount1.mul(98).div(100),
            amountToWithdraw
        );
    },

    async addLiquidity(token0Address, token1Address, amount0, amount1) {
        let decimals0 = this.getTokenDecimal(token0Address);
        let decimals1 = this.getTokenDecimal(token1Address);

        amount0 = ethers.utils.parseUnits(
            new Decimal(amount0).toFixed(decimals0),
            decimals0
        );

        amount1 = ethers.utils.parseUnits(
            new Decimal(amount1).toFixed(decimals1),
            decimals1
        );

        return await web3Service.addLiquidity(
            token0Address,
            token1Address,
            amount0,
            amount1,
            amount0.mul(98).div(100),
            amount1.mul(98).div(100)
        );
    },

    async lockVRSW(amount, duration) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.lockVRSW(formattedAmount, duration);
    },

    async unlockVRSW(amount) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.unlockVRSW(formattedAmount);
    },

    async stakeVRSW(amount) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.stakeVRSW(formattedAmount);
    },

    async claimVRSWRewards() {
        return await web3Service.claimVRSWRewards();
    },

    async claimRewards(lpToken) {
        return await web3Service.claimRewards(lpToken);
    },

    async viewVRSWRewards() {
        return await web3Service.viewVRSWRewards();
    },

    async viewLPRewards(lpToken) {
        return await web3Service.viewLPRewards(lpToken);
    },

    async viewLPPartnerRewards(lpToken, partnerToken) {
        return await web3Service.viewPartnerRewards(lpToken, partnerToken);
    },

    async stakeLPToken(lpToken, amount) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.stakeLPToken(lpToken, formattedAmount);
    },

    async unstakeLPToken(lpToken, amount) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.unstakeLPToken(lpToken, formattedAmount);
    },

    async viewVRSWStake() {
        let stakes = await web3Service.viewLpStakes();
        let vrswStake = stakes
            // eslint-disable-next-line array-callback-return
            .map((stake) => {
                if (stake.lpToken === constants.AddressZero) {
                    let aStake = {};
                    aStake.lpToken = stake.lpToken;
                    aStake.bn = stake.amount.toString();
                    aStake.amount = ethers.utils.formatUnits(stake.amount, 18);
                    return aStake;
                }
            })
            .filter((stake) => stake !== undefined);

        return vrswStake[0];
    },

    async viewLPStakes() {
        let stakes = await web3Service.viewLpStakes();
        stakes = stakes
            // eslint-disable-next-line array-callback-return
            .map((stake) => {
                if (stake.lpToken !== constants.AddressZero) {
                    let aStake = {};
                    aStake.lpToken = stake.lpToken;
                    aStake.bn = stake.amount.toString();
                    aStake.amount = ethers.utils.formatUnits(stake.amount, 18);
                    return aStake;
                }
            })
            .filter((stake) => stake !== undefined);

        return stakes;
    },

    async getBalance(network) {
        const balance = await web3Service.getBalance(network);
        return ethers.utils.formatUnits(balance, 18);
    },

    async getERC20Balance(tokenAddress, network) {
        tokenAddress = tokenAddress?.toString();
        let balance = await web3Service.getERC20Balance(tokenAddress, network);
        let tokenDecimals = await this.getTokenDecimal(tokenAddress);
        return ethers.utils.formatUnits(balance, tokenDecimals);
    },

    async getProfileTokensERC20Balances(ProfileToken) {
        if (!ProfileToken) return {};
        const chainId = ProfileToken[0].chainId;

        const network = this.getRpcUrl(chainId) ?? chainId;

        const [addresses, decimals] = _.unzip(
            ProfileToken.map(token => [token.address, token.decimals]),
        );

        const balances = await web3Service.getERC20Balances(addresses, network);

        return {
            [chainId]: Object.fromEntries(balances.map((balance, i) =>
                [addresses[i], ethers.utils.formatUnits(balance, decimals[i])]
            )),
        };
    },

    async getAllERC20Balances() {
        const { ProfileToken1, ProfileToken2 } = store.getState().Trade;

        if (ProfileToken1?.[0]?.chainId && ProfileToken1[0].chainId === ProfileToken2?.[0]?.chainId) {
            return this.getProfileTokensERC20Balances(ProfileToken1);
        }

        const [balances1, balances2] = await Promise.all([
            this.getProfileTokensERC20Balances(ProfileToken1),
            this.getProfileTokensERC20Balances(ProfileToken2),
        ]);

        return {
            ...balances1,
            ...balances2,
        };
    },

    async addTokenToMetamask(tokenAddress, tokenSymbol, decimals, logo) {
        tokenAddress = tokenAddress?.toString();
        return await web3Service.addTokenToMetamask(
            tokenAddress,
            tokenSymbol,
            decimals,
            logo
        );
    },

    async addBloxRouteRPCToMetamask() {
        return await web3Service.addBloxRouteToMetamask();
    },

    async unstakeVRSW(amount) {
        const formattedAmount = ethers.utils.parseUnits(
            new Decimal(amount).toFixed(18)
        );
        return await web3Service.unstakeVRSW(formattedAmount);
    },

    async approveLPTokenStaker(lpToken) {
        return await web3Service.approveLPTokenStaker(lpToken);
    },

    async approveVRSWStaking() {
        return await web3Service.approveVRSWStaker();
    },

    async approvegVRSWStaker() {
        return await web3Service.approvegVRSWStaker();
    },

    async getRouterERC20Allowance(tokenAddress, convertToNumber=true) {
        tokenAddress = tokenAddress?.toString();
        const allowance = await web3Service.getRouterERC20Allowance(tokenAddress);
        const tokenDecimals = this.getTokenDecimal(tokenAddress);
        const formattedAllowance = ethers.utils.formatUnits(allowance, tokenDecimals);
        return convertToNumber ? parseFloat(formattedAllowance) : formattedAllowance;
    },

    async getPoolCurrentReserveRatio(poolAddress) {
        return await web3Service.getPoolCurrentReserveRatio(poolAddress);
    },

    async getMaxVirtualTradeAmountRtoN(ik, jk) {
        return await web3Service.getMaxVirtualTradeAmountRtoN(ik, jk);
    },

    async scanLPTokens(chainId) {
        chainId ??= defaultChainId;
        const signerAddress = await web3Service.getSignerAddress();
        let lpTokens = (
            await axiosApiVirtuSwap.get(
                GET_LP_BALANCES + '?userAddress=' + signerAddress + '&chainId=' + chainId,
            )
        ).data;
        lpTokens.forEach(
            (lpToken) =>
                (lpToken.balance = ethers.utils.formatEther(lpToken.balance))
        );
        /*
        let lpTokens = await web3Service.scanLPTokens(
            store.getState().Trade.PoolsList
        );*/
        return lpTokens;
    },

    async getPoolLPTokensBalance(poolAddress) {
        let totalSupply = await web3Service.getTokenTotalSupply(poolAddress);
        totalSupply = ethers.utils.formatUnits(totalSupply, 18);
        return totalSupply;
    },

    async prepareSwapTransactions(
        tokenIn,
        tokenOut,
        amount,
        isExactInput,
        chainId,
        guid
    ) {
        if (!tokenIn || !tokenOut) return null;
        try {
            const slippageTolerance = parseFloat(
                store.getState().Trade.txSetting.slippage
            );

            const slippage = 100 / slippageTolerance;

            //amount to BN
            const amountBN = ethers.utils.parseUnits(
                new Decimal(amount)?.toFixed(isExactInput ? tokenIn.decimals : tokenOut.decimals),
                isExactInput ? tokenIn.decimals : tokenOut.decimals
            );

            if (amountBN.eq(0)) return null;

            chainId ??= await web3Service.getCurrentChainId();
            const router = new Router({ slippage, isExactInput, timeoutMs: 750 });
            const rTokenIn = new RouterToken(
                chainId,
                tokenIn.address.toString(),
                tokenIn.decimals
            );
            if (tokenIn?.native) rTokenIn.native = true;
            const rTokenOut = new RouterToken(
                chainId,
                tokenOut.address.toString(),
                tokenOut.decimals,
            );
            if (tokenOut?.native) rTokenOut.native = true;
            const route = await router.getRoute(
                rTokenIn,
                rTokenOut,
                amountBN,
                chainId,
                { slippage, isExactInput },
            );

            for (const step of route.steps) {
                let routeAmountInDecimals = parseFloat(
                    ethers.utils.formatUnits(step.amountInBn, tokenIn.decimals)
                );
                step.weight =
                    (routeAmountInDecimals /
                        parseFloat(route.tokenIn.balance)?.toFixed(tokenIn.decimals)) *
                    100;

                if (step.type === SwapType.DIRECT) {
                    let pool = this.getPoolFromStoreByTokens(
                        step.path[0].address,
                        step.path[1].address
                    );

                    let vLiqA = parseFloat(
                        ethers.utils.formatUnits(
                            this.getBalanceForTokenBN(pool, step.path[0].address),
                            step.path[0].decimals
                        )
                    );

                    let vLiqB = parseFloat(
                        ethers.utils.formatUnits(
                            this.getBalanceForTokenBN(pool, step.path[1].address),
                            step.path[1].decimals
                        )
                    );

                    let routeAmountIn = parseFloat(
                        ethers.utils.formatUnits(
                            step.amountInBn,
                            step.path[0].decimals
                        )
                    );

                    step.metrics = this.calculateNativeTradeMetrics(
                        routeAmountIn,
                        vLiqA,
                        vLiqB
                    );
                } else if (step.type === SwapType.TRIANGULAR) {
                    let paths = step.path.map((r) => r.address.toString());

                    let amountsOut = await web3Service.getAmountsOut(
                        paths,
                        step.amountInBn
                    );
                    let poolA = this.getPoolFromStoreByTokens(
                        step.path[0].address,
                        step.path[1].address
                    );

                    let poolB = this.getPoolFromStoreByTokens(
                        step.path[1].address,
                        step.path[2].address
                    );

                    let balanceA0 = parseFloat(
                        ethers.utils.formatUnits(
                            this.getBalanceNotForTokenBN(
                                poolA,
                                step.path[0].address
                            ),
                            step.path[0].decimals
                        )
                    );

                    let balanceA1 = parseFloat(
                        ethers.utils.formatUnits(
                            this.getBalanceNotForTokenBN(
                                poolA,
                                step.path[1].address
                            ),
                            step.path[1].decimals
                        )
                    );

                    let balanceB0 = parseFloat(
                        ethers.utils.formatUnits(
                            this.getBalanceNotForTokenBN(
                                poolB,
                                step.path[1].address
                            ),
                            step.path[1].decimals
                        )
                    );

                    let balanceB1 = parseFloat(
                        ethers.utils.formatUnits(
                            this.getBalanceNotForTokenBN(
                                poolB,
                                step.path[2].address
                            ),
                            step.path[2].decimals
                        )
                    );

                    let amountOutLeg1 = parseFloat(
                        ethers.utils.formatUnits(
                            amountsOut[1],
                            step.path[1].decimals
                        )
                    );

                    let amountOutLeg2 = parseFloat(
                        ethers.utils.formatUnits(
                            amountsOut[2],
                            step.path[2].decimals
                        )
                    );

                    let routeAmountIn = parseFloat(
                        ethers.utils.formatUnits(
                            step.amountInBn,
                            step.path[0].decimals
                        )
                    );

                    step.metrics = this.calculateTriangularPriceImpact(
                        routeAmountIn,
                        amountOutLeg1,
                        amountOutLeg2,
                        balanceA0,
                        balanceA1,
                        balanceB0,
                        balanceB1,
                        0.003
                    );
                } else if (step.type === SwapType.VIRTUAL) {
                    let ikPool = this.getPoolFromStore(step.ikPair);
                    let jkPool = this.getPoolFromStore(step.jkPair);

                    const virtualPool = await web3Service.getVirtualPool(
                        jkPool.poolAddress,
                        ikPool.poolAddress,
                    );

                    let vLiqA = parseFloat(
                        ethers.utils.formatUnits(
                            virtualPool.balance0,
                            step.path[0].decimals
                        )
                    );

                    let vLiqC = parseFloat(
                        ethers.utils.formatUnits(
                            virtualPool.balance1,
                            step.path[2].decimals
                        )
                    );

                    let jkLiqA = parseFloat(
                        ethers.utils.formatUnits(
                            this.getBalanceNotForTokenBN(
                                jkPool,
                                step.path[2].address
                            ),
                            step.path[2].decimals
                        )
                    );

                    let jkLiqB = parseFloat(
                        ethers.utils.formatUnits(
                            this.getBalanceNotForTokenBN(
                                jkPool,
                                step.path[1].address
                            ),
                            step.path[1].decimals
                        )
                    );

                    let ikLiqC = parseFloat(
                        ethers.utils.formatUnits(
                            this.getBalanceNotForTokenBN(
                                ikPool,
                                step.path[0].address
                            ),
                            step.path[0].decimals
                        )
                    );

                    let ikLiqB = parseFloat(
                        ethers.utils.formatUnits(
                            this.getBalanceNotForTokenBN(
                                ikPool,
                                step.path[1].address
                            ),
                            step.path[1].decimals
                        )
                    );

                    let routeAmountIn = parseFloat(
                        ethers.utils.formatUnits(
                            step.amountInBn,
                            step.path[0].decimals
                        )
                    );

                    step.metrics = this.calculateVirtualTradeMetrics(
                        routeAmountIn,
                        vLiqA,
                        vLiqC,
                        jkLiqA,
                        jkLiqB,
                        ikLiqC,
                        ikLiqB
                    );
                }
            }

            const totalIn = route.tokenIn.balance;
            const totalOut = route.tokenOut.balance;
            const slippageThreshold = route.slippageThresholdAmount.balance;

            console.log('totalIn', totalIn.toString());
            console.log('totalOut', totalOut.toString());
            console.log('slippageThreshold', slippageThreshold.toString());

            const directPCT = _.sum(
                route.steps.filter((r) => r.type === SwapType.DIRECT).map((r) => r.weight)
            );
            const triangularPct = _.sum(
                route.steps.filter((r) => r.type === SwapType.TRIANGULAR).map((r) => r.weight)
            );
            const vPoolPCT = _.sum(
                route.steps.filter((r) => r.type === SwapType.VIRTUAL).map((r) => r.weight)
            );

            let totalFees = route.steps.map((r) => {
                let rAmountIn = parseFloat(
                    ethers.utils.formatUnits(r.amountInBn, tokenIn.decimals)
                );

                if (r.type === SwapType.TRIANGULAR) return 0.006 * rAmountIn;
                else return 0.003 * rAmountIn;
            });

            const avgFee = _.sum(totalFees) / parseFloat(totalIn);

            let totalSavings = _.sumBy(
                route.steps.filter((r) => r.type === SwapType.VIRTUAL),
                (t) =>
                    t.metrics?.vSwapSavings
                        ? t.metrics.vSwapSavings * (parseFloat(t.weight) / 100)
                        : 0
            );

            const priceImpact = _.sum(
                route.steps.map((r) => {
                    const { metrics: { priceImpact } = {}, type } = r;
                    if (!priceImpact) return 0;
                    if (type === SwapType.DIRECT) return priceImpact * r.weight;
                    if (type === SwapType.TRIANGULAR) return priceImpact * r.weight;
                    if (type === SwapType.VIRTUAL) return priceImpact * r.weight;
                    return 0;
                })
            );

            if (!totalSavings) totalSavings = 0;

            return {
                route: route,
                totalIn: isExactInput ? totalIn : slippageThreshold,
                totalOut: totalOut,
                expectedAmountIn: totalIn,
                minAmountOut: isExactInput ? slippageThreshold : totalOut,
                realPoolPCT: directPCT,
                vPoolPCT: vPoolPCT,
                triangularPct: triangularPct,
                price0: (totalOut / totalIn).toFixed(6),
                price1: (totalIn / totalOut).toFixed(6),
                totalSavings: totalSavings.toFixed(2),
                priceImpact: priceImpact.toFixed(3),
                guid: guid,
                avgFee: avgFee * 100,
            };
        } catch (ex) {
            console.error(ex);
            return {
                route: null,
                guid,
            };
        }
    },

    async executeRoute(route) {
      const router = new Router();
      return await web3Service.useSigner(
          async (signer) => router.generateMulticallData(route, await signer.getAddress())
              .then(data => router.executeMulticall(
                  route.chain,
                  data,
                  signer,
                  route.tokenIn.isNative ? route.isExactInput ? route.tokenIn.balanceBN : route.slippageThresholdAmount.balanceBN : undefined,
              )),
      );
    },

    // Tokens By Reserve Pool
    async getTokensByReservePool(poolReserves, profileToken, liquidity_pool) {
        if (liquidity_pool) {
            const result = [];
            poolReserves?.forEach((arr) => {
                const tempResult = [];
                arr.forEach((address) => {
                    const found = profileToken?.find(
                        (temp) => temp?.address === address
                    );
                    if (found) {
                        tempResult.push(found);
                    }
                });
                result.push(tempResult);
            });
            return result;
        } else {
            let tokens = poolReserves?.map((i) =>
                profileToken?.find(
                    (temp) => i?.reserveAddress === temp?.address
                )
            );
            return tokens;
        }
    },
    //handle Get Balance
    async handleGetBalance(isSection, isToken) {
        let FirstBalance;
        let SecondBalance;
        let result = { FirstBalance: '', SecondBalance: '' };
        //Handle balance query
        if (isSection?.isSwapTokenFirst?.address && !isToken) {
            await this.getERC20Balance(
                isSection?.isSwapTokenFirst?.address
            ).then((status) => {
                FirstBalance = status;
            });
        }
        if (isSection?.isSwapTokenSecond?.address && !isToken) {
            await this.getERC20Balance(
                isSection?.isSwapTokenSecond?.address
            ).then((status) => {
                SecondBalance = status;
            });
        }
        result = { FirstBalance: FirstBalance, SecondBalance: SecondBalance };
        return result;
    },
    async cumulativeDataForPeriod(data, period) {
        const n = getSubperiodsNumber(period);
        let to = new Date(Date.now());
        to.setMilliseconds(0);
        to.setSeconds(0);
        to.setMinutes(0);
        if (period !== '1D' && period !== '1W') to.setHours(0);
        const from = (data.length ? new Date(data[0].timestamp) : to).valueOf();
        const subperiodLength = getSubperiodLength(period, from, to);

        let result = [];
        for (let i = n - 1; i >= 0; --i) {
            result.push({
                timestamp: new Date(to - subperiodLength * i),
                value: 0,
            });
        }

        const resultReversed = result.reverse();
        for (let i = 0; i < data.length; ++i) {
            const timestamp = new Date(data[i].timestamp);
            const obj = resultReversed.find(
                (v) => timestamp >= v.timestamp
            );
            if (obj) obj.value += parseFloat(data[i].value);
        }
        resultReversed.reverse();
        return result;
    },
};
