import {
  GET_POOL_RESERVES,
  GET_POOL_RESERVES_SUCCESS,
  GET_POOL_RESERVES_ERROR,
  GET_POOL_BY_ADDRESS,
  GET_TOKEN_BY_ADDRESS,
  GET_RESERVES_TOKEN,
  GET_WITHDRAW_POOL_RESERVES_SUCCESS,
  GET_RECEIVE_WITHDRAW_TOKEN_LIST,
  FETCH_WITHDRAW_POOLS,
  FETCH_WITHDRAW_POOLS_SUCCESS,
  FETCH_WITHDRAW_POOLS_ERROR,
  GET_SYNCING_LP,
  GET_ALLOWED_LIST,
  GET_PL_NATIVE_ASSETS_LIST,
} from "./actionTypes";

const initialStates = {
  poolReserves: [],
  poolByAddress: [],
  tokenByAddress: [],
  reserveToken: [],
  withdrawDetails: "",
  withdrawPoolReserves: [],
  withdrawTokensList: [],
  withdrawPoolList: [],
  lpPoolDetails: [],
  syncingLP: false,
  allowedList: [],
  plNativeList: [],
};

export const LiquidityReducer = (state = initialStates, action) => {
  const { type, payload } = action;
  switch (type) {
    //get poolReserves
    case GET_POOL_RESERVES:
      return {
        ...state,
      };
    case GET_POOL_RESERVES_SUCCESS:
      return {
        ...state,
        poolReserves: payload.map((resolve) => resolve.data),
      };
    case GET_POOL_RESERVES_ERROR:
      return {
        ...state,
      };

    //get withdraw poolReserves
    case GET_WITHDRAW_POOL_RESERVES_SUCCESS:
      return {
        ...state,
        withdrawPoolReserves: payload,
      };

    //get pool by address
    case GET_POOL_BY_ADDRESS:
      return {
        ...state,
        poolByAddress: payload,
      };

    //get token by address
    case GET_TOKEN_BY_ADDRESS:
      return {
        ...state,
        tokenByAddress: payload,
      };

    //get reserves Tokens
    case GET_RESERVES_TOKEN:
      return {
        ...state,
        reserveToken: payload,
      };

    //get Receive withdraw Tokens list
    case GET_RECEIVE_WITHDRAW_TOKEN_LIST:
      return {
        ...state,
        withdrawTokensList: payload,
      };

    //Fetch Withdraw Pools
    case FETCH_WITHDRAW_POOLS:
      return {
        ...state,
      };
    case FETCH_WITHDRAW_POOLS_SUCCESS:
      return {
        ...state,
        withdrawPoolList: payload,
      };
    case FETCH_WITHDRAW_POOLS_ERROR:
      return {
        ...state,
      };

    //get Syncing your LP
    case GET_SYNCING_LP:
      return {
        ...state,
        syncingLP: payload,
      };

    //get allowed list in add liquidity
    case GET_ALLOWED_LIST:
      return {
        ...state,
        allowedList: payload,
      };

    //get Native assets list in add liquidity
    case GET_PL_NATIVE_ASSETS_LIST:
      return {
        ...state,
        plNativeList: payload,
      };
    default:
      return state;
  }
};

export default LiquidityReducer;
