import { useState, useCallback, useEffect } from 'react';
import ThemeContext from './ThemeContext';

const ThemeContextProvider = ({ children }) => {
    const [theme, setTheme] = useState<'dark' | 'light'>('dark');

    useEffect(() => {
        const localVirtuSwapTheme = localStorage.getItem('VirtuSwapTheme');
        const savedTheme = localVirtuSwapTheme === 'light' ? 'light' : 'dark';
        setTheme(savedTheme);
        document.documentElement.setAttribute('data-theme', savedTheme);
    }, []);

    //Set VirtuSwap theme light/dark
    const switchThemes = useCallback(() => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        document.documentElement.setAttribute('data-theme', newTheme);
        localStorage.setItem('VirtuSwapTheme', newTheme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, switchThemes }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;
