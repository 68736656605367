import {
    TOGGLE_WALLET_LOADING_POPUP,
    GET_WALLET_INFO,
    SET_HAS_TRIED_TO_CONNECT,
    GET_WEB3_CONNECTED,
    GET_WALLET_ADDRESS_LOADER,
    GET_WALLET_ADDRESS_SUCCESS,
    SET_CURRENT_CHAIN_ID,
    SET_TRANSACTION_LOADER,
    GET_CONNECTED_ADDRESS,
    GET_USER_BALANCES_LOADER,
    GET_USER_BALANCES_SUCCESS,
    GET_USER_BALANCES_ERROR,
    GET_FAUCET_TOKEN,
    GET_PROFILE_TOKEN,
    GET_PROFILE1_SUCCESS,
    GET_PROFILE2_SUCCESS,
    GET_PROFILE_ERROR,
    FETCH_POOLS,
    FETCH_POOLS_RAW_SUCCESS,
    FETCH_POOLS_SUCCESS,
    FETCH_POOLS_ERROR,
    GET_SLIPPAGE,
    GET_RECENT_TX,
    SET_TOTAL_POOLS,
    SET_CALCULATED_TRANSACTIONS,
    SET_TRADE_LOAD_STATUS,
    GET_PROFILE_CHAINS,
    GET_PROFILE_CHAINS_SUCCESS,
    GET_PROFILE_CHAINS_ERROR,
    GET_OTHER_SWAP_OPTIONS,
    GET_PROFILE_SELECTED_CHAINS,
} from './actionsTypes';
import { utils } from 'ethers';
import vSwapHelpers from 'util/vSwapHelpers';
import { axiosApiVirtuSwap } from 'helpers/api_helper';
import {
    FETCH_CHAIN,
    FETCH_POOLS_LIST,
    FETCH_TOKEN,
    SAVE_WALLET_ADDRESS,
} from 'helpers/url_helper';
import { defaultChainId } from 'components/DummyData';

export const getWalletAddress = (data) => {
    return async (dispatch) => {
        try {
            dispatch(getWalletAddressLoader(true));
            axiosApiVirtuSwap.post(SAVE_WALLET_ADDRESS, {
                address: data,
            });
        } catch (err) {
            dispatch(getWalletAddressLoader(false));
        }
    };
};

//Get User Balances
export const getUserBalances = () => {
  return async (dispatch) => {
    dispatch(getUserBalancesLoader());
    try {
        const balances = await vSwapHelpers.getAllERC20Balances();
        dispatch(getUserBalancesSuccess(balances));
    }  catch (err) {
        dispatch(getUserBalancesError());
    }
  };
};

//get Profile Token
export const getHandleProfileToken = (
    selectedChain,
    cb = () => {},
    payload
) => {
    return async (dispatch) => {
        try {
            dispatch(
                getProfileToken({
                    loader: true,
                    isRefresh: true,
                    part: selectedChain?.part,
                    switch: !!selectedChain?.switch,
                })
            );
            if (!selectedChain?.switch) {
                if (selectedChain?.part === 1 || !selectedChain?.part) {
                    const chainId1 = selectedChain?.chainId1?.id
                        ?? defaultChainId;
                    const res1 = await axiosApiVirtuSwap.get(
                        FETCH_TOKEN + '?chainId=' + chainId1,
                        payload
                    );
                    if (res1?.data) {
                        dispatch(getProfileToken1Success(res1?.data));
                        cb(res1?.data);
                    }
                    if (!selectedChain?.part) {
                        const chainId2 = selectedChain?.chainId2?.id
                            ?? defaultChainId;
                        const res2 = chainId2 === chainId1
                            ? res1
                            : await axiosApiVirtuSwap.get(
                                FETCH_TOKEN + '?chainId=' + chainId2,
                                payload,
                            );
                        if (res2?.data) {
                            dispatch(getProfileToken2Success(res2?.data));
                            cb(res2?.data);
                        }
                    }
                } else if (selectedChain?.part === 2) {
                    const chainId2 = selectedChain?.chainId2?.id
                        ?? defaultChainId;
                    const res2 = await axiosApiVirtuSwap.get(
                        FETCH_TOKEN + '?chainId=' + chainId2,
                        payload
                    );
                    if (res2?.data) {
                        dispatch(getProfileToken2Success(res2?.data));
                        cb(res2?.data);
                    }
                }
                if (selectedChain?.fetchBalances) dispatch(getUserBalances());
            }
        } catch (err) {
            dispatch(getProfileTokenError(err.message));
        }
    };
};

//Fetch Pools
export const handleFetchPools = (chainId, payload = {}, cb = () => {}) => {
    return async (dispatch) => {
        try {
            chainId ??= defaultChainId;
            dispatch(fetchPools(true));
            const res = await axiosApiVirtuSwap.get(FETCH_POOLS_LIST + '?chainId=' + chainId, payload);
            dispatch(fetchPoolsRawSuccess(res.data));
            const data = JSON.parse(JSON.stringify(res.data));
            for (let i = 0; i < data.length; i++) {
                if (!data[i]) continue;
                data[i].chainId = chainId;
                if (data[i].balance0 && data[i].decimals0) {
                    data[i].balance0 = utils.formatUnits(
                        data[i].balance0,
                        data[i].decimals0
                    );
                }

                if (data[i].balance1 && data[i].decimals1) {
                    data[i].balance1 = utils.formatUnits(
                        data[i].balance1,
                        data[i].decimals1
                    );
                }
            }
            dispatch(fetchPoolsSuccess({ [chainId]: data }));
            cb(data);
        } catch (err) {
            dispatch(fetchPoolsError(err.message));
        }
    };
};

//get Profile Chains
export const getHandleProfileChains = (cb = () => {}) => {
    return async (dispatch) => {
        try {
            dispatch(getProfileChains(true));
            const res = await axiosApiVirtuSwap.get(FETCH_CHAIN);
            if (res?.data) {
                dispatch(getProfileChainsSuccess(res.data));
                cb(res.data);
            }
        } catch (err) {
            dispatch(getProfileChainsError(err.message));
        }
    };
};

//Other swap options
export const handleOtherSwapOptionsPromises = ({
    CrossChainOptionsPromises,
    setIsSection,
    setIsAmountFetchedOnce,
    isExactIn,
}) => {
    return async (dispatch, getState) => {
        const {
            ProfileToken1,
            ProfileToken2,
            ChainList,
        } = getState().Trade;

        try {
            if (!!CrossChainOptionsPromises?.length) {
                const ProfileToken  = [...ProfileToken1, ...ProfileToken2];
                const formattedCrossChainOptions = (await Promise.allSettled(
                    CrossChainOptionsPromises.map(async (promise) => {
                        const option = await promise;
                        const fromTokenInfo = ProfileToken.find(
                            (token) =>
                                token?.address?.toLowerCase() ===
                                option?.route?.fromToken?.toLowerCase()
                        );
                        const toTokenInfo = ProfileToken.find((token) => {
                            return (
                                token?.address?.toLowerCase() ===
                                option?.route?.toToken?.toLowerCase()
                            );
                        });
                        const formattedOption = {
                            ...option,
                            fromTokenInfo: fromTokenInfo,
                            toTokenInfo: toTokenInfo,
                            fromChainInfo: ChainList?.find(
                                (item) =>
                                    item?.id === option?.route?.fromChainId
                            ),
                            toChainInfo: ChainList?.find(
                                (item) => item?.id === option?.route?.toChainId
                            ),
                        };
                        if (formattedOption.guid !== sessionStorage.getItem('GUID')) throw new Error('Expired result');
                        const newOptions = [
                            formattedOption,
                            ...getState().Trade.SwapOptionsList
                                .filter(option => option.name !== formattedOption.name),
                        ];
                        dispatch(
                            getSwapOptionsList({
                                formattedCrossChainOptions: newOptions,
                                loader: false,
                            })
                        );
                        const best = newOptions.reduce(
                            (best, { route }) =>
                                Math.max(best, Number(isExactIn ? route.toAmount : route.fromAmount)),
                            0);
                        const newBest = best < 0.00001
                            ? '<0.00001'
                            : best.toLocaleString('en-US', {
                                maximumFractionDigits: best
                                    ?.toString()
                                    ?.split('.')[1]?.length,
                            });
                        setIsSection((prev) => ({
                            ...prev,
                            isValueFirst: isExactIn ? prev.isValueFirst : newBest,
                            isValueSecond: isExactIn ? newBest : prev.isValueSecond,
                        }));
                        return formattedOption;
                    })
                )).filter(r => r.status === 'fulfilled').map(r => r.value);
                if (!formattedCrossChainOptions.length) throw new Error('No routes found');
                if (formattedCrossChainOptions[0]?.guid !== sessionStorage.getItem('GUID')) throw new Error('Expired result');
                dispatch(
                    getSwapOptionsList({
                        formattedCrossChainOptions: formattedCrossChainOptions,
                        allLoaded: true,
                        loader: false,
                    })
                );
                setIsAmountFetchedOnce(true);
            } else {
                dispatch(
                    getSwapOptionsList({
                        formattedCrossChainOptions: [],
                        loader: false,
                        allLoaded: true,
                    })
                );
            }
            dispatch(
                setIsTradeLoad({
                    id: isExactIn ? 2 : 1,
                    status: false,
                })
            );
        } catch (err) {
            if (err.message === 'Expired result') return;
            console.error(err);
            dispatch(
                getSwapOptionsList({
                    formattedCrossChainOptions: [],
                    allLoaded: true,
                    loader: false,
                })
            );
            dispatch(
                setIsTradeLoad({
                    id: isExactIn ? 2 : 1,
                    status: false,
                })
            );
        }
    };
};

export const toggleWalletLoadingPopup = (data) => ({
    type: TOGGLE_WALLET_LOADING_POPUP,
    payload: data,
});

export const getWalletInfo = (data) => ({
    type: GET_WALLET_INFO,
    payload: data,
});

export const setHasTriedToConnect = (data) => ({
    type: SET_HAS_TRIED_TO_CONNECT,
    payload: data,
});

export const getWeb3Connected = (data) => ({
    type: GET_WEB3_CONNECTED,
    payload: data,
});

export const getWalletAddressLoader = (data) => ({
    type: GET_WALLET_ADDRESS_LOADER,
    payload: data,
});
export const getwalletAddressSuccess = (data) => ({
    type: GET_WALLET_ADDRESS_SUCCESS,
    payload: data,
});

export const getUserBalancesLoader = (data) => ({
  type: GET_USER_BALANCES_LOADER,
  payload: data,
});
export const getUserBalancesSuccess = (data) => ({
  type: GET_USER_BALANCES_SUCCESS,
  payload: data,
});
export const getUserBalancesError = (data) => ({
  type: GET_USER_BALANCES_ERROR,
  payload: data,
});

export const setTotalPools = (data) => ({
    type: SET_TOTAL_POOLS,
    payload: data,
});

//set Current Chain Id
export const setCurrentChainId = (data) => ({
    type: SET_CURRENT_CHAIN_ID,
    payload: data,
});

export const setTransactionLoader = (data) => ({
    type: SET_TRANSACTION_LOADER,
    payload: data,
});

export const getConnectedAddress = (data) => ({
    type: GET_CONNECTED_ADDRESS,
    payload: data,
});

//Faucet Page
export const getFaucetToken = (data) => ({
    type: GET_FAUCET_TOKEN,
    payload: data,
});

//get Profile Token
export const getProfileToken = (data) => ({
    type: GET_PROFILE_TOKEN,
    payload: data,
});
export const getProfileToken1Success = (data) => ({
    type: GET_PROFILE1_SUCCESS,
    payload: data,
});
export const getProfileToken2Success = (data) => ({
    type: GET_PROFILE2_SUCCESS,
    payload: data,
});
export const getProfileTokenError = (data) => ({
    type: GET_PROFILE_ERROR,
    payload: data,
});

//Fetch Pools
export const fetchPools = (data) => ({
    type: FETCH_POOLS,
    payload: data,
});

export const fetchPoolsRawSuccess = (data) => ({
    type: FETCH_POOLS_RAW_SUCCESS,
    payload: data,
});

export const fetchPoolsSuccess = (data) => ({
    type: FETCH_POOLS_SUCCESS,
    payload: data,
});
export const fetchPoolsError = (data) => ({
    type: FETCH_POOLS_ERROR,
    payload: data,
});

//Get Slippage tolerance
export const getSlippageTolerance = (data) => ({
    type: GET_SLIPPAGE,
    payload: data,
});

//Get Recent Transactions
export const getRecentTransactions = (data) => ({
    type: GET_RECENT_TX,
    payload: data,
});

export const setCalculatedTransactions = (data) => ({
    type: SET_CALCULATED_TRANSACTIONS,
    payload: data,
});

//set Trade Load Status
export const setIsTradeLoad = (data) => ({
    type: SET_TRADE_LOAD_STATUS,
    payload: data,
});

//get Profile Chains
export const getProfileChains = (data) => ({
    type: GET_PROFILE_CHAINS,
    payload: data,
});
export const getProfileChainsSuccess = (data) => ({
    type: GET_PROFILE_CHAINS_SUCCESS,
    payload: data,
});
export const getProfileChainsError = (data) => ({
    type: GET_PROFILE_CHAINS_ERROR,
    payload: data,
});

//Other Swap options
export const getSwapOptionsList = (data) => ({
    type: GET_OTHER_SWAP_OPTIONS,
    payload: data,
});

//get Profile Selected Chain
export const getSelectedChain = (data) => ({
    type: GET_PROFILE_SELECTED_CHAINS,
    payload: data,
});
