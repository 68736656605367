export const TOGGLE_WALLET_LOADING_POPUP = 'TOGGLE_WALLET_LOADING_POPUP';
export const GET_WALLET_INFO = 'GET_WALLET_INFO';
export const SET_HAS_TRIED_TO_CONNECT = 'SET_HAS_TRIED_TO_CONNECT';
export const GET_WEB3_CONNECTED = 'GET_WEB3_CONNECTED';
export const SET_CURRENT_CHAIN_ID = 'SET_CURRENT_CHAIN_ID';
export const SET_TRANSACTION_LOADER = 'SET_TRANSACTION_LOADER';
export const GET_CONNECTED_ADDRESS = 'GET_CONNECTED_ADDRESS';

//Get User Balances
export const GET_USER_BALANCES_LOADER = 'GET_USER_BALANCES_LOADER';
export const GET_USER_BALANCES_SUCCESS = 'GET_USER_BALANCES_SUCCESS';
export const GET_USER_BALANCES_ERROR = 'GET_USER_BALANCES_ERROR';

//SaveWalletAddress
export const GET_WALLET_ADDRESS_LOADER = 'GET_WALLET_ADDRESS_LOADER';
export const GET_WALLET_ADDRESS_SUCCESS = 'GET_WALLET_ADDRESS_SUCCESS';

//Faucet Page
export const GET_FAUCET_TOKEN = 'GET_FAUCET_TOKEN';

//get Profile Token
export const GET_PROFILE_TOKEN = 'GET_PROFILE_TOKEN';
export const GET_PROFILE1_SUCCESS = 'GET_PROFILE1_SUCCESS';
export const GET_PROFILE2_SUCCESS = 'GET_PROFILE2_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';

//Fetch Pools
export const FETCH_POOLS = 'FETCH_POOLS';
export const FETCH_POOLS_SUCCESS = 'FETCH_POOLS_SUCCESS';
export const FETCH_POOLS_RAW_SUCCESS = 'FETCH_POOLS_RAW_SUCCESS';

export const FETCH_POOLS_ERROR = 'FETCH_POOLS_ERROR';

//Get Slippage tolerance
export const GET_SLIPPAGE = 'GET_SLIPPAGE';

//Get Recent Transactions
export const GET_RECENT_TX = 'GET_RECENT_TX';
export const SET_CALCULATED_TRANSACTIONS = 'SET_CALCULATED_TRANSACTIONS';

//save tPools
export const SET_TOTAL_POOLS = 'SET_TOTAL_POOLS';

//set Trade Load Status
export const SET_TRADE_LOAD_STATUS = 'SET_TRADE_LOAD_STATUS';

//get Profile Chains
export const GET_PROFILE_CHAINS = 'GET_PROFILE_CHAINS';
export const GET_PROFILE_CHAINS_SUCCESS = 'GET_PROFILE_CHAINS_SUCCESS';
export const GET_PROFILE_CHAINS_ERROR = 'GET_PROFILE_CHAINS_ERROR';

//Other swap options
export const GET_OTHER_SWAP_OPTIONS = 'GET_OTHER_SWAP_OPTIONS';

//get Profile Selected Chain
export const GET_PROFILE_SELECTED_CHAINS = 'GET_PROFILE_SELECTED_CHAINS';
