//Fetch Pools Info
export const FETCH_POOLS_INFO = 'FETCH_POOLS_INFO';
export const FETCH_POOLS_INFO_SUCCESS = 'FETCH_POOLS_INFO_SUCCESS';
export const FETCH_POOLS_INFO_ERROR = 'FETCH_POOLS_INFO_ERROR';
//Fetch Pool Info Reserved
export const FETCH_POOLS_INFO_RESERVED = 'FETCH_POOLS_INFO_RESERVED';
//Set Pool Info Chart Data List
export const SET_POOLS_INFO_GRAPH_LIST = 'SET_POOLS_INFO_GRAPH_LIST';
//Fetch Pools List Featured
export const FETCH_POOLS_FEATURED = 'FETCH_POOLS_FEATURED';
export const FETCH_POOLS_FEATURED_SUCCESS = 'FETCH_POOLS_FEATURED_SUCCESS';
export const FETCH_POOLS_FEATURED_ERROR = 'FETCH_POOLS_FEATURED_ERROR';
