import { serializeError } from 'eth-rpc-errors';

const errorMapping = {
    // VirtuSwap router errors:

    'VSWAP:EXPIRED': 'Expired deadline for transaction',
    'Not WETH9': 'Failure to receive native tokens',
    'VSWAP: TRANSFER FAILED': 'Failure to transfer native tokens',
    'VSWAP: INPUT TOKEN MUST BE WETH9': 'Invalid router path for native->ERC20 swap',
    'VSWAP: INSUFFICIENT_INPUT_AMOUNT': 'Insufficient amount of available input tokens – failure due to price update',
    'VSWAP: OUTPUT TOKEN MUST BE WETH9': 'Invalid router path for ERC20->native swap',
    'VSWAP: REQUIRED_AMOUNT_EXCEEDS': 'Insufficient amount of required output tokens – failure due to price update',
    'VSWAP: REQUIRED_VINPUT_EXCEED': 'Insufficient amount of available input tokens for virtual pool swap – failure due to price update',
    'VSWAP: INSUFFICIENT_VOUTPUT_AMOUNT': 'Insufficient amount of required output tokens for virtual pool swap – failure due to price update',
    'VSWAP: INSUFFICIENT_ETH_INPUT_AMOUNT': 'Insufficient amount of available input tokens – failure due to price update',
    'VSWAP: INSUFFICIENT_A_AMOUNT': 'Insufficient amount of one of the assets – failure due to price update',
    'VSWAP: INSUFFICIENT_B_AMOUNT': 'Insufficient amount of one of the assets – failure due to price update',

    // VirtuSwap swap library errors:

    'VSWAP: INSUFFICIENT_AMOUNT': 'Insufficient amount of available input tokens – failure due to price update',
    'VSWAP: INSUFFICIENT_LIQUIDITY': 'Insufficient liquidity in the pool',
    'VSWAP: LOCKED_VPOOL': 'Virtual pool temporarily locked due to price update. Please try again in a minute',
    'VSWAP: INVALID_VPOOL': 'Virtual pool path failure due to price update. Please try again in a minute',
    'VSWAP: NOT_ALLOWED': 'Virtual pool path failure due to price update',

    // VirtuSwap pools errors:

    'TBPT': 'Virtual pool path failure due to reaching reserve threshold. Please try again',
    'FBF': 'Balance fetching failure. Please try again in a minute',
    'IT': 'Invalid receiver address',
    'NNT': 'Path failure. Please try again',
    'IAO': 'Please specify positive amount of output tokens',
    'AOE': 'Please specify amount of output tokens that is lower than pool balance',
    'IIA': 'Insufficient amount of one of the assets',
    'IIKP': 'Path failure. Please try again',
    'TNW': 'Path failure. Please try again',
    'IBD': 'Insufficient amount of input assets. Please try again',
    'ILB': 'Please specify amounts different from zero',
    'ILM': 'Please specify amounts different from zero',

    // ERC20 errors:

    'ERC20: decreased allowance below zero': 'Please specify nonnegative input tokens allowance',
    'ERC20: transfer amount exceeds balance': 'Amount of input tokens exceeds balance',
    'ERC20: transfer amount exceeds allowance': 'Please increase input tokens allowance',
    'ERC20: insufficient balance': 'Amount of input tokens exceeds balance',
    'ERC20: insufficient allowance': 'Please increase input tokens allowance',

    // Wallet errors:

    'user rejected transaction': 'The transaction signature was denied. Please try again and sign the transaction',
    'underlying network changed': 'It looks like you are connected to the wrong network. Please check your network and try again',
} as Record<string, string>;

type Resolver = Partial<Record<'inputToken' | 'outputToken' | 'nativeToken', string>>;

export function getFriendlyErrorMessage(error: Error, resolverMapping?: Resolver, checkHtmlMessage = false): string {
    const serializedError = serializeError(error);
    const data = (serializedError?.data as any);
    const errorMessage = (((data && ('originalError' in data)) ?
            (data.originalError.reason ?? data.originalError.message ??
                (checkHtmlMessage ?
                    data.originalError?.htmlMessage?.replace('<br/>', '\n') :
                    undefined)) :
            serializedError?.message) ??
        error?.message)?.replace('execution reverted: ', '');

    let resolvedError = errorMapping[errorMessage] ??
        'An error has occurred. Please try again in a minute';

    if (resolverMapping) {
        resolvedError = resolvedError.replaceAll(
            /((input)|(output)|(native)) tokens/g, found =>
            resolverMapping[(found.split(' ')[0] + 'Token') as keyof Resolver]
                ?? found
        );
    }

    return `${resolvedError}\n\nError: ${errorMessage}`;
}
