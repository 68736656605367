import {
    FETCH_POOLS_INFO,
    FETCH_POOLS_INFO_SUCCESS,
    FETCH_POOLS_INFO_ERROR,
    FETCH_POOLS_INFO_RESERVED,
    SET_POOLS_INFO_GRAPH_LIST,
    FETCH_POOLS_FEATURED,
    FETCH_POOLS_FEATURED_SUCCESS,
    FETCH_POOLS_FEATURED_ERROR,
} from './actionType';
import moment from 'moment';
import { BigNumber, utils } from 'ethers';
import vSwapHelpers from 'util/vSwapHelpers';
import { GraphOneOption, defaultChainId } from 'components/DummyData';
import { axiosApiVirtuSwap } from 'helpers/api_helper';
import { getAllowedList, getPLNativeAssetsList } from 'store/action';
import { FETCH_INFO_POOLS, FETCH_PLS_FEATURED } from 'helpers/url_helper';
import {
    convertDataToRange,
    generateGraphList,
    handleThousandSeparator,
} from 'components/common/CommonFunctions';

//Fetch Pools Info
export const handleFetchInfoPools = ({
    cb = () => {},
    poolAddress,
    chainId,
    payload,
}) => {
    return async (dispatch) => {
        try {
            chainId ??= defaultChainId;
            dispatch(fetchPoolsInfo(true));
            const res = await axiosApiVirtuSwap.get(
                FETCH_INFO_POOLS + '?poolAddress=' + poolAddress + '&chainId=' + chainId,
                payload
            );
            if (res.data) {
                for (let i = 0; i < res.data?.reserves.length; i++) {
                    const balanceString = String(res.data?.reserves[i].balance);
                    const decimalsNumber = parseInt(
                        res.data?.reserves[i].decimals
                    );
                    // Validate input before formatting
                    if (
                        !BigNumber.isBigNumber(balanceString) ||
                        isNaN(decimalsNumber)
                    ) {
                        continue;
                    }
                    // Create a new instance of BigNumber and format units accordingly
                    const balanceBN = BigNumber.from(balanceString);
                    res.data.reserves[i].balance = utils.formatUnits(
                        balanceBN,
                        decimalsNumber
                    );
                }
                dispatch(fetchPoolsInfoSuccess(res.data));
                cb(res.data);
            }
        } catch (err) {
            dispatch(fetchPoolsInfoError(err.message));
            dispatch(fetchPoolsInfoReserved([]));
            dispatch(getAllowedList([]));
            dispatch(getPLNativeAssetsList([]));
        }
    };
};

//Fetch Pool Info Reserved
export const handleFetchInfoPoolsReserved = (
    address,
    infoPool,
    cb = () => {},
    currentList,
    isSection,
    reservedList
) => {
    return async (dispatch) => {
        try {
            let current_token_list = [];
            let i_Token = [];
            let lastReservedList = [];
            if (!currentList && !isSection) {
                await Promise.all(address?.map((add) => add)).then((status) => {
                    if (status.length > 0) {
                        let lastUsd = status?.map((i) =>
                            Number(i?.lastUSDPrice)
                        );
                        let totalValue = status?.map(
                            (i, index) =>
                                Number(infoPool?.reserves[index].balance) *
                                Number(i?.lastUSDPrice)
                        );
                        let TotalPoolTVL =
                            !!totalValue.length &&
                            totalValue?.reduce((a, b) => a + b, 0);
                        status.map((i, index) =>
                            i_Token.push({
                                title: i?.symbol ? i?.symbol : '',
                                subTitle:
                                    i?.name?.lastIndexOf(' -') > 0
                                        ? i?.name?.slice(
                                              0,
                                              i?.name?.lastIndexOf(' -')
                                          )
                                        : i?.name,
                                icon: i?.icon ? i?.icon : '',
                                tokenId: i?.tokenId ? i?.tokenId : '',
                                currencyValue: infoPool?.reserves[index].balance
                                    ? Number(
                                          infoPool?.reserves[index].balance
                                      ).toFixed(3)
                                    : '',
                                totalValue: Number(
                                    infoPool?.reserves[index].balance
                                )
                                    ? `${(
                                          Number(
                                              infoPool?.reserves[index].balance
                                          ) * Number(i?.lastUSDPrice)
                                      ).toFixed(3)}`
                                    : '0',
                                value: index + 1,
                                USD_value: lastUsd.reduce((a, b) => a + b, 0),
                                address: i?.address,
                                TVL:
                                    ((Number(
                                        infoPool?.reserves[index].balance
                                    ) *
                                        Number(i?.lastUSDPrice)) /
                                        TotalPoolTVL) *
                                    100,
                            })
                        );
                    } else {
                        cb();
                    }
                });
            } else {
                if (!!currentList.length) {
                    let balance0 = infoPool?.pool
                        ? vSwapHelpers.formatUnits(
                              infoPool?.pool?.balance0,
                              infoPool?.pool?.decimals0
                          )
                        : 0;
                    let balance1 = infoPool?.pool
                        ? vSwapHelpers.formatUnits(
                              infoPool?.pool?.balance1,
                              infoPool?.pool?.decimals1
                          )
                        : 0;

                    let tokenList = [
                        {
                            PoolList: currentList[0],
                            TokenDetails: isSection?.isSwapTokenFirst,
                            balance:
                                isSection?.isSwapTokenFirst?.address?.toLowerCase() ===
                                infoPool?.pool?.token0?.toLowerCase()
                                    ? balance0
                                    : balance1,
                        },
                        {
                            PoolList: currentList[0],
                            TokenDetails: isSection?.isSwapTokenSecond,
                            balance:
                                isSection?.isSwapTokenSecond?.address?.toLowerCase() ===
                                infoPool?.pool?.token1?.toLowerCase()
                                    ? balance1
                                    : balance0,
                        },
                    ];
                    if (!!tokenList?.length) {
                        let totalValue = [];
                        let lastUsd = tokenList?.map((i) =>
                            Number(i?.TokenDetails?.lastUSDPrice)
                        );
                        if (!!reservedList?.length) {
                            lastUsd.push(reservedList[0]?.USD_value);
                            totalValue = reservedList?.map((i) =>
                                Number(i?.totalValue.replace('~$', ''))
                            );
                        }
                        tokenList?.map((item) =>
                            totalValue.push(
                                Number(item?.balance) *
                                    Number(item?.TokenDetails?.lastUSDPrice)
                            )
                        );
                        let TotalPoolTVL = !!totalValue.length
                            ? totalValue?.reduce((a, b) => a + b, 0)
                            : 1;
                        if (!!reservedList?.length) {
                            lastReservedList = reservedList?.map((element) => {
                                return {
                                    ...element,
                                    TVL:
                                        (Number(
                                            element?.totalValue.replace(
                                                '~$',
                                                ''
                                            )
                                        ) /
                                            TotalPoolTVL) *
                                        100,
                                    totalValue: Number(
                                        element?.totalValue
                                            ?.toString()
                                            ?.replace('~$', '')
                                    ),
                                };
                            });
                        }
                        tokenList?.map((i, index) =>
                            current_token_list.push({
                                title: i?.TokenDetails?.PriceTag,
                                subTitle:
                                    i?.TokenDetails?.name?.lastIndexOf(' -') > 0
                                        ? i?.TokenDetails?.name?.slice(
                                              0,
                                              i?.TokenDetails?.name?.lastIndexOf(
                                                  ' -'
                                              )
                                          )
                                        : i?.TokenDetails?.name,
                                icon: i?.TokenDetails?.icon,
                                tokenId: i?.TokenDetails?.tokenId,
                                currencyValue: Number(i?.balance),
                                totalValue: `${(
                                    Number(i?.balance) *
                                    Number(i?.TokenDetails?.lastUSDPrice)
                                ).toFixed(2)}`,
                                value: index + 1,
                                USD_value: lastUsd.reduce((a, b) => a + b, 0),
                                address: i?.TokenDetails?.address,
                                TVL:
                                    ((Number(i?.balance) *
                                        Number(i?.TokenDetails?.lastUSDPrice)) /
                                        TotalPoolTVL) *
                                    100,
                            })
                        );
                    }
                }
            }
            if (!!i_Token.length || !!current_token_list.length) {
                let final_result;
                if (!!current_token_list.length) {
                    final_result = lastReservedList;
                } else {
                    final_result = i_Token;
                }
                dispatch(getPLNativeAssetsList(current_token_list));
                dispatch(
                    fetchPoolsInfoReserved(
                        !!final_result?.length ? final_result : []
                    )
                );
                cb(final_result, current_token_list);
            }
        } catch (err) {
            console.log('err', err);
        }
    };
};

//Set Pool Info Chart Data List
export const handleSetPoolsInfoGraphList = ({
    status,
    GraphDetailsList,
    isActive,
    chainId,
}) => {
    return async (dispatch) => {
        try {
            const graphListsByHistory = await Promise.all((GraphOneOption || [])
                .map(async (item) => {
                    switch (item.name) {
                        case 'Volume':
                            return await generateGraphList(
                                item.name,
                                status.volumeHistory
                            );
                        case 'TVL':
                            return await generateGraphList(
                                item.name,
                                status.tvlHistory
                            );
                        case 'Fees':
                            return await generateGraphList(
                                item.name,
                                status.feesHistory
                            );
                        default:
                            return {}; // Default case
                    }
                })
            );
            const graphOneList = GraphOneOption.map((item) => {
                const graphListByHistory = graphListsByHistory.find(list => list.name === item.name);
                return {
                    ...item,
                    graphList: graphListByHistory.graphList,
                    duration: graphListByHistory?.duration,
                    totalAmount: handleThousandSeparator(
                        graphListByHistory?.totalAmount
                    ),
                };
            }) || [];
            if (!!graphOneList?.length) {
                // Set Graph Details List
                const graphTwoList =
                    (await Promise.all(
                        GraphDetailsList?.map(async (item) => {
                            const graphListByHistory = graphListsByHistory
                                .find(list => list.name === isActive.name); // should be refactored
                            const graphList = await convertDataToRange({
                                historyData: graphListByHistory?.historyData,
                                period: item?.name,
                                tagName: graphListByHistory?.name,
                            });
                            return {
                                ...item,
                                graphList: graphList,
                            };
                        })
                    )) || [];
                //Set Both Graph List
                dispatch(setPoolsInfoGraphList({
                    [chainId]: { graphOneList, graphTwoList }
                }));
            }
        } catch (err) {
            console.error(err);
        }
    };
};

//Fetch Pools List Featured
export const getPoolsFeatured = (chainId, payload = {}, cb = () => {}) => {
    return async (dispatch) => {
        try {
            chainId ??= defaultChainId;
            dispatch(fetchPoolsFeatured(true));
            const res = await axiosApiVirtuSwap.get(
                FETCH_PLS_FEATURED + '?chainId=' + chainId,
                payload
            );
            const data = res?.data;
            if (data) {
                ['feesHistory', 'volumeHistory', 'tvlHistory'].forEach(
                    key => data[key] = data[key]?.map(obj => ({
                        ...obj,
                        timestamp: moment.utc(obj.timestamp).toISOString(), //TODO: move to backend?
                    })),
                );
                dispatch(fetchPoolsFeaturedSuccess({ [chainId]: data }));
                cb(data);
            }
        } catch (err) {
            dispatch(fetchPoolsFeaturedError(err.message));
        }
    };
};

//Fetch Pools Info
export const fetchPoolsInfo = (data) => ({
    type: FETCH_POOLS_INFO,
    payload: data,
});
export const fetchPoolsInfoSuccess = (data) => ({
    type: FETCH_POOLS_INFO_SUCCESS,
    payload: data,
});
export const fetchPoolsInfoError = (data) => ({
    type: FETCH_POOLS_INFO_ERROR,
    payload: data,
});

//Fetch Pool Info Reserved
export const fetchPoolsInfoReserved = (data) => ({
    type: FETCH_POOLS_INFO_RESERVED,
    payload: data,
});

//Set Pool Info Chart Data List
export const setPoolsInfoGraphList = (data) => ({
    type: SET_POOLS_INFO_GRAPH_LIST,
    payload: data,
});

//Fetch Pools List Featured
export const fetchPoolsFeatured = (data) => ({
    type: FETCH_POOLS_FEATURED,
    payload: data,
});
export const fetchPoolsFeaturedSuccess = (data) => ({
    type: FETCH_POOLS_FEATURED_SUCCESS,
    payload: data,
});
export const fetchPoolsFeaturedError = (data) => ({
    type: FETCH_POOLS_FEATURED_ERROR,
    payload: data,
});
