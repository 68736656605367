//get poolReserves
export const GET_POOL_RESERVES = "GET_POOL_RESERVES";
export const GET_POOL_RESERVES_SUCCESS = "GET_POOL_RESERVES_SUCCESS";
export const GET_POOL_RESERVES_ERROR = "GET_POOL_RESERVES_ERROR";

//get pool by address
export const GET_POOL_BY_ADDRESS = "GET_POOL_BY_ADDRESS";

//get token by address
export const GET_TOKEN_BY_ADDRESS = "GET_TOKEN_BY_ADDRESS";

//get reserves Tokens
export const GET_RESERVES_TOKEN = "GET_RESERVES_TOKEN";

//get withdraw poolReserves
export const GET_WITHDRAW_POOL_RESERVES_SUCCESS =
  "GET_WITHDRAW_POOL_RESERVES_SUCCESS";

//get Receive withdraw Tokens list
export const GET_RECEIVE_WITHDRAW_TOKEN_LIST =
  "GET_RECEIVE_WITHDRAW_TOKEN_LIST";

//Fetch Withdraw Pools
export const FETCH_WITHDRAW_POOLS = "FETCH_WITHDRAW_POOLS";
export const FETCH_WITHDRAW_POOLS_SUCCESS = "FETCH_WITHDRAW_POOLS_SUCCESS";
export const FETCH_WITHDRAW_POOLS_ERROR = "FETCH_WITHDRAW_POOLS_ERROR";

//get Syncing your LP
export const GET_SYNCING_LP = "GET_SYNCING_LP";

//get allowed list in add liquidity
export const GET_ALLOWED_LIST = "GET_ALLOWED_LIST";

//get Native assets list in add liquidity
export const GET_PL_NATIVE_ASSETS_LIST = "GET_PL_NATIVE_ASSETS_LIST";
