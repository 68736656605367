import { combineReducers } from 'redux';
import TradeReducer from './Trade/reducer';
import LiquidityReducer from './Liquidity/reducer';
import PoolsInfoReducer from './Info/reducer';
import VRSWReducer from './VRSW/reducer';

export default combineReducers({
    Trade: TradeReducer,
    Liquidity: LiquidityReducer,
    Info: PoolsInfoReducer,
    VRSW: VRSWReducer,
});
