//Fetch VRSW Positions
export const FETCH_VRSW_POSITIONS = "FETCH_VRSW_POSITIONS";
export const FETCH_VRSW_POSITIONS_SUCCESS = "FETCH_VRSW_POSITIONS_SUCCESS";
export const FETCH_VRSW_POSITIONS_ERROR = "FETCH_VRSW_POSITIONS_ERROR";
//Set VRSW Pool Block
export const SET_VRSW_POOL_BLOCK = "SET_VRSW_POOL_BLOCK";
//Set VRSW Pool Loader
export const SET_VRSW_POOL_LOADER = "SET_VRSW_POOL_LOADER";
//Fetch Boost Calculator
export const FETCH_BOOST_CAL = "FETCH_BOOST_CAL";
export const FETCH_BOOST_CAL_SUCCESS = "FETCH_BOOST_CAL_SUCCESS";
export const FETCH_BOOST_CAL_ERROR = "FETCH_BOOST_CAL_ERROR";
//Set Staking LP Tokens list
export const SET_STAKING_LP_TOKENS = "SET_STAKING_LP_TOKENS";
export const SET_STAKING_LP_TOKENS_SUCCESS = "SET_STAKING_LP_TOKENS_SUCCESS";
export const SET_STAKING_LP_TOKENS_ERROR = "SET_STAKING_LP_TOKENS_ERROR";
